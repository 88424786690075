body {
    background-color: black;
}

header {
    $breakpoint-hamburger: breakpoint("large-tablet");

    width: 100%;
    height: responsive($HEADER-HEIGHT);

    &.fixed {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 100;
    }

    & > .background {
        background-color: black;
    }

    & > .container {
        height: 100%;

        & > .inner {
            height: 100%;
        }
    }

    a {
        color: white;

        &:hover {
            @include color("orange");
        }
    }

    .logo {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 1;

        & > a {
            display: block;
            width: responsive(102px);
            position: absolute;
            left: 0;
            top: responsive(14px);
        }
    }

    nav {
        color: white;

        ul {
            & > li {
                @include inline-block($vertical-align: top);
                height: 100%;

                & > a {
                    display: block;
                    height: 100%;
                }
            }

            &.social {
                .svg-img {
                    @include valign-middle;
                }
            }
        }
    }

    a.hamburger {
        $size: 35px;
        $padding: 10px;

        display: none;
        @include below-breakpoint($breakpoint-hamburger) {
            display: block;
        }
        width: $size + (2 * $padding);
        height: $size + (2 * $padding);
        padding: $padding;
        @include position(right, center);
        right: -5px;
        z-index: 1;

        .close-nav {
            display: none;
        }
    }

    &.light {
        & > .background {
            background-color: white;
        }

        a {
            color: black;

            &:hover {
                @include color("orange");
            }
        }

        nav {
            color: black;
        }
    }

    &.featured {
        & > .background {
            opacity: 0;
        }

        .logo {
            transform: scale(1.53);
        }
    }

    @include above-breakpoint($breakpoint-hamburger) {
        .hamburger-show {
            display: none;
        }

        nav {
            $height: 30px;

            height: 100%;

            & > .inner {
                width: 100%;
                height: responsive($height);
                position: absolute;
                @include valign-middle;
            }

            ul {
                height: 100%;
                position: absolute;
                white-space: nowrap;

                & > li {
                    & > a {
                        padding: 0 responsive(15px);
                        @include font-size("default");
                        line-height: responsive($height);
                    }
                }

                &.main {
                    left: responsive(190px);
                }

                &.social {
                    right: 0;

                    & > li {
                        & > a {
                            padding: 0 responsive(6px);

                            .svg-img {
                                @include responsive-above-breakpoint("mini-laptop", (width: 15px));
                            }
                        }

                        &.language {
                            margin-right: responsive(12px);
                        }
                    }
                }
            }
        }
    }

    @include below-breakpoint($breakpoint-hamburger) {
        .hamburger-hide {
            display: none;
        }

        nav {
            $width: 300px;

            width: $width;
            height: 100vh;
            padding: 40px;
            padding-top: 70px;
            position: fixed;
            right: -$width;
            top: 0;
            z-index: 1;
            border-left: 1px solid rgba(white, 0.1);
            background-color: black;
            @include scrollable-y;
            transform: translate(0%, 0);
            transition: transform 0.5s;

            @include below-breakpoint("mobile") {
                $width: 250px;

                width: $width;
                right: -$width;
                padding: 30px;
                padding-top: 70px;
            }

            ul {
                &.main {
                    margin-bottom: 25px;

                    & > li {
                        display: block;

                        & > a {
                            display: block;
                            height: 100%;
                            padding: 8px 0;
                            @include font-size("h2", "h2");
                            @include line-height("tight");
                        }
                    }
                }

                &.social {
                    height: 38px;
                    margin-left: -20px;
                    margin-right: -15px;

                    & > li {
                        width: 25%;

                        & > a {
                            .svg-img {
                                width: 25px;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }

            .social-hd {
                margin-bottom: 15px;
                @include font-size("small");
                text-transform: uppercase;
            }
        }

        &.light {
            nav {
                border-left: 1px solid rgba(black, 0.1);
                background-color: white;
            }
        }

        &.nav-visible {
            nav {
                transform: translate(-100%, 0);
            }

            a.hamburger {
                .show-nav {
                    display: none;
                }

                .close-nav {
                    display: block;
                }
            }
        }
    }
}

main {
    min-height: 100vh;
    background-color: white;
    overflow: hidden;

    header.fixed:not(.featured) + & {
        padding-top: responsive($HEADER-HEIGHT);
    }
}

footer {
    padding-top: responsive(20px);
    padding-bottom: responsive(25px);
    background-color: black;
    color: white;
    text-align: center;
}
