ul.categories-grid {
    @include responsive-gap(30px, $use-padding: true);
    display: flex;
    flex-wrap: wrap;

    & > li {
        width: 50%;

        @include below-breakpoint("large-tablet") {
            width: 100%;
        }

        & > a {
            display: block;
            @include aspect-ratio(3 / 2);
            background-color: black;
            overflow: hidden;

            .background {
                transform: scale(1.01);
                transition: transform 0.25s;
            }

            .category-hover {
                @include position(center, center);
                background-color: rgba(black, 0.4);
                color: white;
                text-shadow: 0 0 responsive(10px) black;
                text-align: center;
                opacity: 1;
                transition: background-color 0.25s, color 0.25s, opacity 0.25s;
            }

            .category-hover-inner {
                width: 100%;
                padding: 0 responsive(15px);
                position: absolute;
                @include valign-middle;
            }

            h2 {
                @include font-size("h1-small", "h1");
                @include line-height("tight");
            }

            &:hover {
                .background {
                    transform: scale(1.1);
                }

                .category-hover {
                    background-color: rgba(black, 0.8);
                    @include color("orange");
                    opacity: 1;
                }
            }
        }
    }
}
