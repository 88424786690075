.section-contact-body {
    .contact-body-map {
        display: flex !important;

        & > div {
            width: 100%;

            & > iframe {
                display: block;
                width: 100%;
                height: 100%;
                padding: responsive(8px);
                position: absolute;
                border: 2px solid #e5e5e5;

                @include below-breakpoint("small-tablet") {
                    height: responsive(400px);
                    position: relative;
                }
            }
        }
    }
}
