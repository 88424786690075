.focuspoint {
    img {
        opacity: 0;
    }

    &.ready {
        img {
            opacity: 1;
        }
    }
}