.section-team-header {
    .h2 {
        @include above-breakpoint("small-tablet") {
            padding-right: 25%;
        }
    }
}

.section-team-list {
    ul.team-grid {
        @include responsive-gap(10px);
        display: flex;
        flex-wrap: wrap;

        & > li {
            display: block;
            width: (100% / 6);
            opacity: 0;

            @include below-breakpoint("laptop") {
                width: (100% / 5)
            }

            @include below-breakpoint("medium-tablet") {
                width: (100% / 4)
            }

            @include below-breakpoint("mini-tablet") {
                width: (100% / 3)
            }

            @include below-breakpoint("medium-mobile") {
                width: (100% / 2);
            }

            .person {
                @include aspect-ratio(230 / 310);
                background-color: black;
            }

            .person-info {
                width: 100%;
                padding: responsive(10px);
                position: absolute;
                left: 0;
                bottom: 0;
                color: white;
                text-align: left;

                h2, h3 {
                    @include ellipsis;
                }

                h2 {
                    @include font-size("h3", "h3");
                    @include line-height("tight");
                }

                h3 {
                    margin-top: responsive(2px);
                    @include font-size("small");
                }
            }

            .person-overlay-default, .person-overlay-hover {
                @include position(center, center);
                transition: opacity 0.25s;
            }

            .person-overlay-default {
                background-image: linear-gradient(to top, rgba(black, 0.7), rgba(black, 0), rgba(black, 0));
            }

            .person-overlay-hover {
                background-color: rgba(black, 0.8);
                opacity: 0;
            }

            &:hover {
                .person-overlay-default {
                    opacity: 0;
                }

                .person-overlay-hover {
                    opacity: 1;
                }
            }
        }
    }
}
