.section-news-list {
    ul.news-list {
        @include responsive-gap(10px);
        display: flex;
        flex-direction: column;

        & > li {
            display: flex;
            align-items: flex-start;

            @include below-breakpoint("mobile") {
                flex-direction: column;
            }

            & + li {
                margin-top: responsive(40px);
                padding-top: responsive(40px);
            }

            .news-list-item-date {
                &::before {
                    content: "";
                    display: block;
                    position: absolute;
                    left: -999999px;
                    right: 0;
                    bottom: 0;
                    height: responsive(8px);
                    @include background-color("orange");
                }

                margin-right: responsive(40px);
                margin-bottom: responsive(30px);
                flex-shrink: 0;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                width: responsive(160px);
                padding-top: responsive(3px);
                padding-bottom: responsive(32px);
                text-align: right;
                line-height: 1;
                text-transform: uppercase;

                @include below-breakpoint("mobile") {
                    justify-content: flex-start;
                    text-align: left;
                }

                &-day {
                    @include font-size("h1-small");
                }

                &-month-and-year {
                    margin-top: responsive(5px);
                    @include font-size("h3");
                }
            }
        }
    }
}
