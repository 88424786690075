ul.productions-grid {
    @include responsive-gap(10px);
    display: flex;
    flex-wrap: wrap;

    & > li {
        display: block;
        width: (100% / 3);
        opacity: 0;

        @include below-breakpoint("medium-tablet") {
            width: 50%;
        }

        @include below-breakpoint("medium-mobile") {
            width: 100%;
        }

        & > a {
            display: block;
            @include aspect-ratio(1);
            background-color: black;
            overflow: hidden;

            .background {
                transform: scale(1.01);
                transition: transform 0.25s;
            }

            .production-hover {
                @include position(center, center);
                background-color: rgba(black, 0.8);
                color: white;
                text-align: center;
                opacity: 0;
                transition: opacity 0.25s;
            }

            .production-hover-inner {
                width: 100%;
                padding: 0 responsive(15px);
                position: absolute;
                @include valign-middle;
            }

            h2 {
                @include font-size("h2", "h2");
                @include line-height("tight");
            }

            h3 {
                margin-top: responsive(8px);
                @include font-size("h3", "h3");
                @include font-weight("light");
            }

            &:hover {
                .background {
                    transform: scale(1.1);
                }

                .production-hover {
                    opacity: 1;
                }
            }
        }
    }
}

.section-slideshow {
    margin-bottom: responsive(50px);

    @include below-breakpoint("medium-tablet") {
        margin-bottom: responsive(40px);
    }

    @include below-breakpoint("mobile") {
        .container {
            padding: 0;
        }
    }

    ul.slideshow-slides {
        @include responsive-aspect-ratio(1350 / 545);
        background-color: black;
        overflow: hidden;

        & > li {
            display: none;
            @include fill($use-width-and-height: true);

            &:first-child, &.active, &.animating {
                display: block;
            }
        }
    }

    a.slideshow-arrow {
        $size: 46px;

        @include flex-center;
        width: responsive($size);
        height: responsive($size);
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: black;
        color: white;

        .svg-img {
            width: responsive(14px);
            margin: 0;
            opacity: 0.6;
        }

        &.left {
            left: 0;

            .svg-img {
                left: responsive(-2px);
            }
        }

        &.right {
            right: 0;

            .svg-img {
                right: responsive(-2px);
            }
        }

        &:hover {
            .svg-img {
                opacity: 1;
            }
        }
    }

    .slideshow-nav {
        $min-breakpoint: "tablet";

        height: 0;
        position: absolute;
        @include responsive-above-breakpoint($min-breakpoint, (bottom: 35px));
        left: 0;
        right: 0;
        font-size: 0;
        text-align: center;

        ul {
            @include inline-flex($justify-content: space-between);

            & > li {
                $size: 11px;
                $spacing: 13px;

                @include flex-center;
                @include responsive-above-breakpoint($min-breakpoint, (
                    width: $size + $spacing,
                    height: $size + $spacing,
                ));
                cursor: pointer;

                &::after {
                    content: "";
                    display: block;
                    @include responsive-above-breakpoint($min-breakpoint, (
                        width: $size,
                        height: $size,
                    ));
                    border: 1px solid rgba(black, 0.1);
                    border-radius: 50%;
                    background-color: white;
                }

                &.active {
                    &::after {
                        @include background-color("orange-dark");
                    }
                }
            }
        }
    }
}

.section-production-info {
    $left-width: 47%;
    $right-width: 100% - $left-width;
    $h-spacing: 55px;
    $v-spacing: 30px;
    $video-aspect-ratio-width: 16;
    $video-aspect-ratio-height: 9;

    .info-content {
        @include flex($justify-content: space-between);
        flex-direction: column;
    }

    .info-logo {
        .logo {
            $content-width: breakpoint("@default") - 2 * config("container-padding");
            $left-width-without-spacing: $left-width - ($h-spacing / $content-width * 100%);
            $aspect-ratio-width: $left-width-without-spacing * $video-aspect-ratio-width / $right-width;
            $aspect-ratio-height: $video-aspect-ratio-height;

            @include aspect-ratio($aspect-ratio-width / $aspect-ratio-height);
        }
    }

    .info-video {
        .video {
            @include aspect-ratio($video-aspect-ratio-width / $video-aspect-ratio-height);

            iframe {
                @include fill($use-width-and-height: true);
            }
        }
    }

    .info-media {
        @include flex($justify-content: space-between);
        margin-bottom: responsive($v-spacing);

        .info-logo {
            width: $left-width;
            padding-right: responsive($h-spacing);
        }

        .info-video {
            width: $right-width;
        }
    }

    .info-body {
        @include flex($justify-content: space-between);

        .info-body-meta {
            width: $left-width;
            padding-right: responsive($h-spacing);

            & > * + * {
                margin-top: responsive($v-spacing);
            }

            ul.meta-links {
                @include flex(flex-start);
                margin-left: responsive(-12px);

                & > li {
                    display: block;
                    width: responsive(50px);
                    height: responsive(35px);

                    & > a {
                        @include flex-center;
                        height: 100%;
                        color: black;

                        &:hover {
                            @include color("orange");
                        }

                        .svg-img {
                            width: responsive(22px);
                            height: responsive(22px);
                        }
                    }
                }
            }

            ul.meta-buttons {
                & > li {
                    display: block;

                    & + li {
                        margin-top: responsive(18px);
                    }

                    .button {
                        display: block;
                        width: 100%;
                    }
                }
            }

            table.meta-details {
                td {
                    width: 50%;
                    padding: responsive(12px) responsive(8px);
                    border-bottom: 2px solid white;
                    background-color: #ebebeb;
                    background-clip: padding-box;
                    @include font-size("default");
                    color: #5f5f5f;

                    &:first-child {
                        padding-right: responsive(30px);
                    }
                }
            }

            .meta-networks {
                & > ul {
                    @include responsive-gap(25px, $use-padding: false);

                    & > li {
                        @include inline-block($vertical-align: middle);

                        img {
                            width: auto;
                            max-width: responsive(140px);
                            max-height: responsive(60px);
                        }
                    }
                }
            }
        }

        .info-body-description {
            width: $right-width;

            & > * + * {
                margin-top: responsive($v-spacing);
            }

            .description-awards {
                & > ul {
                    @include responsive-gap(10px, $use-padding: false);

                    & > li {
                        @include inline-flex($vertical-align: bottom, $align-items: center);
                        min-height: responsive(52px);
                        padding: responsive(6px);
                        border: 1px solid #ccc;
                        @include font-size("small");

                        & > .logo {
                            flex-shrink: 0;

                            img {
                                @include responsive((
                                    max-width: 128px,
                                    max-height: 40px,
                                ), "@responsive-base");
                            }

                            &.is-large {
                                img {
                                    @include responsive((
                                        max-width: 192px,
                                        max-height: 60px,
                                    ), "@responsive-base");
                                }
                            }
                        }

                        & > .text {
                            @include responsive((
                                padding: 0 4px,
                            ), "@responsive-base");
                        }

                        & > .logo + .text {
                            @include responsive((
                                margin-left: 6px,
                            ), "@responsive-base");
                        }
                    }
                }
            }
        }
    }

    @include below-breakpoint("medium-tablet") {
        .info-logo {
            display: none;
        }

        .info-media {
            order: 1;
            margin-bottom: 0;
            margin-top: responsive($v-spacing);

            .info-video {
                width: 100%;
            }
        }

        .info-body {
            flex-direction: column;

            .info-body-meta, .info-body-description {
                width: 100%;
            }

            .info-body-meta {
                order: 1;
                padding-right: 0;

                & > :first-child {
                    margin-top: responsive($v-spacing);
                }
            }
        }
    }
}

.section-production-nav {
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    @include background-color("orange");

    .nav-content {
        @include flex($justify-content: space-between);
        flex-wrap: wrap;

        & > a {
            $height: 70px;

            display: block;
            height: responsive($height);
            padding: 0 responsive(20px);
            @include font-size("h3", "h3");
            @include font-weight("light");
            color: white;
            line-height: responsive($height);
            text-transform: uppercase;
            white-space: nowrap;
            @include translucent-on-hover;

            .svg-img {
                width: responsive(15px);
                position: absolute;
                top: 0;
                bottom: 0;
                margin: auto;
            }

            &.previous {
                padding-left: responsive(35px);

                .svg-img {
                    left: 0;
                }
            }

            &.next {
                padding-right: responsive(35px);

                .svg-img {
                    right: 0;
                }
            }
        }
    }
}

.production-2019 {
    &-main {
        padding-top: responsive(20px);
        padding-bottom: responsive(50px);
    }

    &-content {
        @include responsive-gap(35px, $use-padding: false);
        display: flex;

        @include below-breakpoint("small-tablet") {
            flex-direction: column;
        }
    }

    &-col {
        flex: 1;
    }

    &-poster {
        display: block;
    }

    &-links {
        @include responsive-gap(20px, $use-padding: false);
        margin-top: responsive(10px);
        display: flex;
        flex-wrap: wrap;

        &-item {
            display: block;
        }
    }

    &-title {
        @include font-size("h1-small", "h1");
        @include line-height("tight");
    }

    &-videos {
        margin-top: responsive(20px);

        &-item {
            display: block;
            @include aspect-ratio(16 / 9);

            & + * {
                margin-top: responsive(20px);
            }

            iframe {
                @include fill($use-width-and-height: true);
            }
        }
    }

    &-description {
        margin-top: responsive(30px);
    }

    &-details {
        @include responsive-gap(30px);
        margin-top: responsive(15px);
        display: flex;
        flex-wrap: wrap;

        &-item {
            display: block;
            min-width: 50%;
        }

        dt, dd {
            margin: 0;
            padding: 0;
        }

        dt {
            color: #666;
        }
    }

    &-networks {
        @include responsive-gap(20px, $use-padding: false);
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &-item {
            display: block;

            img {
                width: auto;
                @include responsive((
                    max-width: 80px,
                    max-height: 40px,
                ), "@responsive-base");
            }
        }
    }

    &-awards {
        @include responsive-gap(20px, $use-padding: false);
        margin-top: 0;
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        &-item {
            display: flex;
            align-items: center;
            min-height: responsive(52px);
            @include responsive((
                min-height: 52px,
            ), "@responsive-base");
            @include font-size("small");

            &-logo {
                flex-shrink: 0;

                img {
                    @include responsive((
                        max-width: 128px,
                        max-height: 40px,
                    ), "@responsive-base");
                }

                &.is-large {
                    img {
                        @include responsive((
                            max-width: 192px,
                            max-height: 60px,
                        ), "@responsive-base");
                    }
                }
            }

            &-text {
                @include responsive((
                    margin-left: 10px,
                ), "@responsive-base");
            }
        }
    }

    &-slideshow {
        margin-bottom: 0;
        margin-top: responsive(50px);
    }

    &-nav {
        position: relative;
    }
}
