html {
  font-size: 1.27273vw; }
  @media (max-width: 800px) {
    html {
      font-size: 10.18182px; } }
  @media (min-width: 1501px) {
    html {
      font-size: 19.09091px; } }

html {
  box-sizing: border-box;
  background-color: white;
  text-size-adjust: 100%;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

*, *::before, *::after {
  box-sizing: inherit;
  position: relative; }

h1, h2, h3, h4, h5, h6, p, ol, ul {
  padding: 0;
  margin: 0; }

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit; }

a {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent; }

b, strong {
  font-weight: inherit; }

img, svg, video {
  display: block;
  max-width: 100%; }

svg {
  overflow: visible; }

input[type="text"],
input[type="password"],
input[type="email"],
input[type="number"],
input[type="tel"],
input[type="search"],
input[type="date"],
input[type="time"],
input[type="submit"],
textarea,
select,
button,
.button {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin: 0;
  border: 1px solid currentColor;
  border-radius: 0;
  background-color: transparent;
  background-clip: padding-box;
  font: inherit;
  color: inherit;
  -webkit-tap-highlight-color: transparent; }

textarea {
  resize: vertical; }


button,
.button {
  cursor: pointer; }

table {
  width: 100%;
  border-collapse: collapse; }

th, td {
  padding: 0; }

iframe {
  display: block;
  width: 100%;
  max-width: 100%;
  border: 0; }

main {
  display: block; }

[hidden] {
  display: none !important; }

body {
  margin: 0;
  font-family: "Josefin Sans", sans-serif;
  font-size: 1.27273vw;
  font-weight: 400;
  color: black;
  line-height: 1.5; }
  @media (max-width: 1100px) {
    body {
      font-size: 14px; } }
  @media (min-width: 1501px) {
    body {
      font-size: 19.09091px; } }
  body, body * {
    letter-spacing: -0.02em; }
  a {
    color: #dc911b; }
    a:hover {
      color: #ebb25a; }
  b, strong {
    font-weight: 700; }

.container {
  display: block;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 6.81818vw;
  padding-right: 6.81818vw; }
  @media (min-width: 1501px) {
    .container {
      width: 1500px; } }
  @media (min-width: 1501px) {
    .container {
      padding-left: 102.27273px;
      padding-right: 102.27273px; } }

.flex {
  display: flex; }
  .flex > * {
    min-width: 0; }
  .flex.is-inline {
    display: inline-flex; }
  .flex.is-align-left {
    justify-content: flex-start; }
  .flex.is-align-center {
    justify-content: center; }
  .flex.is-align-right {
    justify-content: flex-end; }
  .flex.is-align-justify {
    justify-content: space-between; }
  .flex.is-align-top {
    align-items: flex-start;
    align-content: flex-start; }
  .flex.is-align-middle {
    align-items: center;
    align-content: center; }
  .flex.is-align-bottom {
    align-items: flex-end;
    align-content: flex-end; }
  .flex.is-align-stretch > * {
    display: flex;
    flex-direction: column; }
    .flex.is-align-stretch > * > * {
      min-height: 0; }
    .flex.is-align-stretch > * > * {
      flex-grow: 1; }
  .flex.is-column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    align-content: stretch; }
    .flex.is-column.is-align-left {
      align-items: flex-start;
      align-content: flex-start; }
    .flex.is-column.is-align-center {
      align-items: center;
      align-content: center; }
    .flex.is-column.is-align-right {
      align-items: flex-end;
      align-content: flex-end; }
    .flex.is-column.is-align-top {
      justify-content: flex-start; }
    .flex.is-column.is-align-middle {
      justify-content: center; }
    .flex.is-column.is-align-bottom {
      justify-content: flex-end; }
  .flex.is-wrap {
    flex-wrap: wrap; }
  .flex:not(.is-align-stretch) > li:not(.flex) {
    display: block; }
  .flex > .is-grow {
    flex-grow: 1; }
  .flex > .is-no-shrink {
    flex-shrink: 0; }
  .flex > .is-align-self-top, .flex > .is-align-self-left {
    align-self: flex-start; }
  .flex > .is-align-self-middle, .flex > .is-align-self-center {
    align-self: center; }
  .flex > .is-align-self-bottom, .flex > .is-align-self-right {
    align-self: flex-end; }
  .flex.is-grid {
    flex-wrap: wrap; }
    .flex.is-grid > * {
      flex-basis: 0;
      flex-grow: 1; }
    .flex.is-grid > .is-width-auto, .flex.is-grid > [class^="width-"], .flex.is-grid > [class*=" width-"] {
      flex-basis: auto;
      flex-grow: 0; }
  .flex > .is-1-part {
    flex-grow: 1; }
  .flex > .is-2-parts {
    flex-grow: 2; }
  .flex > .is-3-parts {
    flex-grow: 3; }
  .flex > .is-4-parts {
    flex-grow: 4; }
  .flex > .is-5-parts {
    flex-grow: 5; }
  .flex > .is-6-parts {
    flex-grow: 6; }
  .flex > .is-7-parts {
    flex-grow: 7; }
  .flex > .is-8-parts {
    flex-grow: 8; }
  .flex > .is-9-parts {
    flex-grow: 9; }
  .flex > .is-10-parts {
    flex-grow: 10; }
  .flex.is-1-column > *, .flex[class*="-columns "] > *, .flex[class$="-columns"] > * {
    flex-basis: auto;
    flex-grow: 0; }
  .flex.is-1-column > * {
    width: 100%; }
  .flex.is-2-columns > * {
    width: 50%; }
  .flex.is-3-columns > * {
    width: 33.33333%; }
  .flex.is-4-columns > * {
    width: 25%; }
  .flex.is-5-columns > * {
    width: 20%; }
  .flex.is-6-columns > * {
    width: 16.66667%; }
  .flex.is-7-columns > * {
    width: 14.28571%; }
  .flex.is-8-columns > * {
    width: 12.5%; }
  .flex.is-9-columns > * {
    width: 11.11111%; }
  .flex.is-10-columns > * {
    width: 10%; }
  @media (max-width: 1000px) {
    .flex.is-1-column\@mini-laptop > *, .flex[class*="-columns@mini-laptop "] > *, .flex[class$="-columns@mini-laptop"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@mini-laptop > * {
      width: 100%; }
    .flex.is-2-columns\@mini-laptop > * {
      width: 50%; }
    .flex.is-3-columns\@mini-laptop > * {
      width: 33.33333%; }
    .flex.is-4-columns\@mini-laptop > * {
      width: 25%; }
    .flex.is-5-columns\@mini-laptop > * {
      width: 20%; }
    .flex.is-6-columns\@mini-laptop > * {
      width: 16.66667%; }
    .flex.is-7-columns\@mini-laptop > * {
      width: 14.28571%; }
    .flex.is-8-columns\@mini-laptop > * {
      width: 12.5%; }
    .flex.is-9-columns\@mini-laptop > * {
      width: 11.11111%; }
    .flex.is-10-columns\@mini-laptop > * {
      width: 10%; } }
  @media (max-width: 900px) {
    .flex.is-1-column\@tablet > *, .flex[class*="-columns@tablet "] > *, .flex[class$="-columns@tablet"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@tablet > * {
      width: 100%; }
    .flex.is-2-columns\@tablet > * {
      width: 50%; }
    .flex.is-3-columns\@tablet > * {
      width: 33.33333%; }
    .flex.is-4-columns\@tablet > * {
      width: 25%; }
    .flex.is-5-columns\@tablet > * {
      width: 20%; }
    .flex.is-6-columns\@tablet > * {
      width: 16.66667%; }
    .flex.is-7-columns\@tablet > * {
      width: 14.28571%; }
    .flex.is-8-columns\@tablet > * {
      width: 12.5%; }
    .flex.is-9-columns\@tablet > * {
      width: 11.11111%; }
    .flex.is-10-columns\@tablet > * {
      width: 10%; } }
  @media (max-width: 800px) {
    .flex.is-1-column\@medium-tablet > *, .flex[class*="-columns@medium-tablet "] > *, .flex[class$="-columns@medium-tablet"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@medium-tablet > * {
      width: 100%; }
    .flex.is-2-columns\@medium-tablet > * {
      width: 50%; }
    .flex.is-3-columns\@medium-tablet > * {
      width: 33.33333%; }
    .flex.is-4-columns\@medium-tablet > * {
      width: 25%; }
    .flex.is-5-columns\@medium-tablet > * {
      width: 20%; }
    .flex.is-6-columns\@medium-tablet > * {
      width: 16.66667%; }
    .flex.is-7-columns\@medium-tablet > * {
      width: 14.28571%; }
    .flex.is-8-columns\@medium-tablet > * {
      width: 12.5%; }
    .flex.is-9-columns\@medium-tablet > * {
      width: 11.11111%; }
    .flex.is-10-columns\@medium-tablet > * {
      width: 10%; } }
  @media (max-width: 700px) {
    .flex.is-1-column\@small-tablet > *, .flex[class*="-columns@small-tablet "] > *, .flex[class$="-columns@small-tablet"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@small-tablet > * {
      width: 100%; }
    .flex.is-2-columns\@small-tablet > * {
      width: 50%; }
    .flex.is-3-columns\@small-tablet > * {
      width: 33.33333%; }
    .flex.is-4-columns\@small-tablet > * {
      width: 25%; }
    .flex.is-5-columns\@small-tablet > * {
      width: 20%; }
    .flex.is-6-columns\@small-tablet > * {
      width: 16.66667%; }
    .flex.is-7-columns\@small-tablet > * {
      width: 14.28571%; }
    .flex.is-8-columns\@small-tablet > * {
      width: 12.5%; }
    .flex.is-9-columns\@small-tablet > * {
      width: 11.11111%; }
    .flex.is-10-columns\@small-tablet > * {
      width: 10%; } }
  @media (max-width: 600px) {
    .flex.is-1-column\@mini-tablet > *, .flex[class*="-columns@mini-tablet "] > *, .flex[class$="-columns@mini-tablet"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@mini-tablet > * {
      width: 100%; }
    .flex.is-2-columns\@mini-tablet > * {
      width: 50%; }
    .flex.is-3-columns\@mini-tablet > * {
      width: 33.33333%; }
    .flex.is-4-columns\@mini-tablet > * {
      width: 25%; }
    .flex.is-5-columns\@mini-tablet > * {
      width: 20%; }
    .flex.is-6-columns\@mini-tablet > * {
      width: 16.66667%; }
    .flex.is-7-columns\@mini-tablet > * {
      width: 14.28571%; }
    .flex.is-8-columns\@mini-tablet > * {
      width: 12.5%; }
    .flex.is-9-columns\@mini-tablet > * {
      width: 11.11111%; }
    .flex.is-10-columns\@mini-tablet > * {
      width: 10%; } }
  @media (max-width: 500px) {
    .flex.is-1-column\@mobile > *, .flex[class*="-columns@mobile "] > *, .flex[class$="-columns@mobile"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@mobile > * {
      width: 100%; }
    .flex.is-2-columns\@mobile > * {
      width: 50%; }
    .flex.is-3-columns\@mobile > * {
      width: 33.33333%; }
    .flex.is-4-columns\@mobile > * {
      width: 25%; }
    .flex.is-5-columns\@mobile > * {
      width: 20%; }
    .flex.is-6-columns\@mobile > * {
      width: 16.66667%; }
    .flex.is-7-columns\@mobile > * {
      width: 14.28571%; }
    .flex.is-8-columns\@mobile > * {
      width: 12.5%; }
    .flex.is-9-columns\@mobile > * {
      width: 11.11111%; }
    .flex.is-10-columns\@mobile > * {
      width: 10%; } }
  @media (max-width: 440px) {
    .flex.is-1-column\@medium-mobile > *, .flex[class*="-columns@medium-mobile "] > *, .flex[class$="-columns@medium-mobile"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@medium-mobile > * {
      width: 100%; }
    .flex.is-2-columns\@medium-mobile > * {
      width: 50%; }
    .flex.is-3-columns\@medium-mobile > * {
      width: 33.33333%; }
    .flex.is-4-columns\@medium-mobile > * {
      width: 25%; }
    .flex.is-5-columns\@medium-mobile > * {
      width: 20%; }
    .flex.is-6-columns\@medium-mobile > * {
      width: 16.66667%; }
    .flex.is-7-columns\@medium-mobile > * {
      width: 14.28571%; }
    .flex.is-8-columns\@medium-mobile > * {
      width: 12.5%; }
    .flex.is-9-columns\@medium-mobile > * {
      width: 11.11111%; }
    .flex.is-10-columns\@medium-mobile > * {
      width: 10%; } }
  @media (max-width: 380px) {
    .flex.is-1-column\@small-mobile > *, .flex[class*="-columns@small-mobile "] > *, .flex[class$="-columns@small-mobile"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@small-mobile > * {
      width: 100%; }
    .flex.is-2-columns\@small-mobile > * {
      width: 50%; }
    .flex.is-3-columns\@small-mobile > * {
      width: 33.33333%; }
    .flex.is-4-columns\@small-mobile > * {
      width: 25%; }
    .flex.is-5-columns\@small-mobile > * {
      width: 20%; }
    .flex.is-6-columns\@small-mobile > * {
      width: 16.66667%; }
    .flex.is-7-columns\@small-mobile > * {
      width: 14.28571%; }
    .flex.is-8-columns\@small-mobile > * {
      width: 12.5%; }
    .flex.is-9-columns\@small-mobile > * {
      width: 11.11111%; }
    .flex.is-10-columns\@small-mobile > * {
      width: 10%; } }
  @media (max-width: 320px) {
    .flex.is-1-column\@mini-mobile > *, .flex[class*="-columns@mini-mobile "] > *, .flex[class$="-columns@mini-mobile"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@mini-mobile > * {
      width: 100%; }
    .flex.is-2-columns\@mini-mobile > * {
      width: 50%; }
    .flex.is-3-columns\@mini-mobile > * {
      width: 33.33333%; }
    .flex.is-4-columns\@mini-mobile > * {
      width: 25%; }
    .flex.is-5-columns\@mini-mobile > * {
      width: 20%; }
    .flex.is-6-columns\@mini-mobile > * {
      width: 16.66667%; }
    .flex.is-7-columns\@mini-mobile > * {
      width: 14.28571%; }
    .flex.is-8-columns\@mini-mobile > * {
      width: 12.5%; }
    .flex.is-9-columns\@mini-mobile > * {
      width: 11.11111%; }
    .flex.is-10-columns\@mini-mobile > * {
      width: 10%; } }
  @media (min-width: 1201px) {
    .flex.is-1-column\@medium-laptop > *, .flex[class*="-columns@medium-laptop "] > *, .flex[class$="-columns@medium-laptop"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@medium-laptop > * {
      width: 100%; }
    .flex.is-2-columns\@medium-laptop > * {
      width: 50%; }
    .flex.is-3-columns\@medium-laptop > * {
      width: 33.33333%; }
    .flex.is-4-columns\@medium-laptop > * {
      width: 25%; }
    .flex.is-5-columns\@medium-laptop > * {
      width: 20%; }
    .flex.is-6-columns\@medium-laptop > * {
      width: 16.66667%; }
    .flex.is-7-columns\@medium-laptop > * {
      width: 14.28571%; }
    .flex.is-8-columns\@medium-laptop > * {
      width: 12.5%; }
    .flex.is-9-columns\@medium-laptop > * {
      width: 11.11111%; }
    .flex.is-10-columns\@medium-laptop > * {
      width: 10%; } }
  @media (min-width: 1301px) {
    .flex.is-1-column\@large-laptop > *, .flex[class*="-columns@large-laptop "] > *, .flex[class$="-columns@large-laptop"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@large-laptop > * {
      width: 100%; }
    .flex.is-2-columns\@large-laptop > * {
      width: 50%; }
    .flex.is-3-columns\@large-laptop > * {
      width: 33.33333%; }
    .flex.is-4-columns\@large-laptop > * {
      width: 25%; }
    .flex.is-5-columns\@large-laptop > * {
      width: 20%; }
    .flex.is-6-columns\@large-laptop > * {
      width: 16.66667%; }
    .flex.is-7-columns\@large-laptop > * {
      width: 14.28571%; }
    .flex.is-8-columns\@large-laptop > * {
      width: 12.5%; }
    .flex.is-9-columns\@large-laptop > * {
      width: 11.11111%; }
    .flex.is-10-columns\@large-laptop > * {
      width: 10%; } }
  @media (min-width: 1401px) {
    .flex.is-1-column\@desktop > *, .flex[class*="-columns@desktop "] > *, .flex[class$="-columns@desktop"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@desktop > * {
      width: 100%; }
    .flex.is-2-columns\@desktop > * {
      width: 50%; }
    .flex.is-3-columns\@desktop > * {
      width: 33.33333%; }
    .flex.is-4-columns\@desktop > * {
      width: 25%; }
    .flex.is-5-columns\@desktop > * {
      width: 20%; }
    .flex.is-6-columns\@desktop > * {
      width: 16.66667%; }
    .flex.is-7-columns\@desktop > * {
      width: 14.28571%; }
    .flex.is-8-columns\@desktop > * {
      width: 12.5%; }
    .flex.is-9-columns\@desktop > * {
      width: 11.11111%; }
    .flex.is-10-columns\@desktop > * {
      width: 10%; } }
  @media (min-width: 1501px) {
    .flex.is-1-column\@small-desktop > *, .flex[class*="-columns@small-desktop "] > *, .flex[class$="-columns@small-desktop"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@small-desktop > * {
      width: 100%; }
    .flex.is-2-columns\@small-desktop > * {
      width: 50%; }
    .flex.is-3-columns\@small-desktop > * {
      width: 33.33333%; }
    .flex.is-4-columns\@small-desktop > * {
      width: 25%; }
    .flex.is-5-columns\@small-desktop > * {
      width: 20%; }
    .flex.is-6-columns\@small-desktop > * {
      width: 16.66667%; }
    .flex.is-7-columns\@small-desktop > * {
      width: 14.28571%; }
    .flex.is-8-columns\@small-desktop > * {
      width: 12.5%; }
    .flex.is-9-columns\@small-desktop > * {
      width: 11.11111%; }
    .flex.is-10-columns\@small-desktop > * {
      width: 10%; } }
  @media (min-width: 1601px) {
    .flex.is-1-column\@medium-desktop > *, .flex[class*="-columns@medium-desktop "] > *, .flex[class$="-columns@medium-desktop"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@medium-desktop > * {
      width: 100%; }
    .flex.is-2-columns\@medium-desktop > * {
      width: 50%; }
    .flex.is-3-columns\@medium-desktop > * {
      width: 33.33333%; }
    .flex.is-4-columns\@medium-desktop > * {
      width: 25%; }
    .flex.is-5-columns\@medium-desktop > * {
      width: 20%; }
    .flex.is-6-columns\@medium-desktop > * {
      width: 16.66667%; }
    .flex.is-7-columns\@medium-desktop > * {
      width: 14.28571%; }
    .flex.is-8-columns\@medium-desktop > * {
      width: 12.5%; }
    .flex.is-9-columns\@medium-desktop > * {
      width: 11.11111%; }
    .flex.is-10-columns\@medium-desktop > * {
      width: 10%; } }
  @media (min-width: 1701px) {
    .flex.is-1-column\@large-desktop > *, .flex[class*="-columns@large-desktop "] > *, .flex[class$="-columns@large-desktop"] > * {
      flex-basis: auto;
      flex-grow: 0; }
    .flex.is-1-column\@large-desktop > * {
      width: 100%; }
    .flex.is-2-columns\@large-desktop > * {
      width: 50%; }
    .flex.is-3-columns\@large-desktop > * {
      width: 33.33333%; }
    .flex.is-4-columns\@large-desktop > * {
      width: 25%; }
    .flex.is-5-columns\@large-desktop > * {
      width: 20%; }
    .flex.is-6-columns\@large-desktop > * {
      width: 16.66667%; }
    .flex.is-7-columns\@large-desktop > * {
      width: 14.28571%; }
    .flex.is-8-columns\@large-desktop > * {
      width: 12.5%; }
    .flex.is-9-columns\@large-desktop > * {
      width: 11.11111%; }
    .flex.is-10-columns\@large-desktop > * {
      width: 10%; } }
  .flex > .is-order-1 {
    order: -1; }
  @media (max-width: 1000px) {
    .flex > .is-order-1\@mini-laptop {
      order: -1; } }
  @media (max-width: 900px) {
    .flex > .is-order-1\@tablet {
      order: -1; } }
  @media (max-width: 800px) {
    .flex > .is-order-1\@medium-tablet {
      order: -1; } }
  @media (max-width: 700px) {
    .flex > .is-order-1\@small-tablet {
      order: -1; } }
  @media (max-width: 600px) {
    .flex > .is-order-1\@mini-tablet {
      order: -1; } }
  @media (max-width: 500px) {
    .flex > .is-order-1\@mobile {
      order: -1; } }
  @media (max-width: 440px) {
    .flex > .is-order-1\@medium-mobile {
      order: -1; } }
  @media (max-width: 380px) {
    .flex > .is-order-1\@small-mobile {
      order: -1; } }
  @media (max-width: 320px) {
    .flex > .is-order-1\@mini-mobile {
      order: -1; } }
  @media (min-width: 1201px) {
    .flex > .is-order-1\@medium-laptop {
      order: -1; } }
  @media (min-width: 1301px) {
    .flex > .is-order-1\@large-laptop {
      order: -1; } }
  @media (min-width: 1401px) {
    .flex > .is-order-1\@desktop {
      order: -1; } }
  @media (min-width: 1501px) {
    .flex > .is-order-1\@small-desktop {
      order: -1; } }
  @media (min-width: 1601px) {
    .flex > .is-order-1\@medium-desktop {
      order: -1; } }
  @media (min-width: 1701px) {
    .flex > .is-order-1\@large-desktop {
      order: -1; } }

.inline {
  display: inline; }

.block {
  display: block; }

.inline-block {
  display: inline-block; }

.blocks > * {
  display: block; }

.inline-blocks > * {
  display: inline-block; }

.inline-blocks.is-align-top > * {
  vertical-align: top; }

.inline-blocks.is-align-middle > * {
  vertical-align: middle; }

.inline-blocks.is-align-bottom > * {
  vertical-align: bottom; }

.block-align-left {
  margin-right: auto; }

.block-align-center {
  margin-left: auto;
  margin-right: auto; }

.block-align-right {
  margin-left: auto; }

.vertical-align-top {
  vertical-align: top; }

.vertical-align-middle {
  vertical-align: middle; }

.vertical-align-bottom {
  vertical-align: bottom; }

.fill {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }

.fill-width {
  width: 100%; }

.fill-height {
  height: 100%; }

.fill-view-height {
  min-height: 100vh; }

.contain-width {
  max-width: 100%; }

.width-5 {
  width: 0.35714rem; }

.width-10 {
  width: 0.71429rem; }

.width-15 {
  width: 1.07143rem; }

.width-20 {
  width: 1.42857rem; }

.width-25 {
  width: 1.78571rem; }

.width-30 {
  width: 2.14286rem; }

.width-35 {
  width: 2.5rem; }

.width-40 {
  width: 2.85714rem; }

.width-45 {
  width: 3.21429rem; }

.width-50 {
  width: 3.57143rem; }

.width-55 {
  width: 3.92857rem; }

.width-60 {
  width: 4.28571rem; }

.width-65 {
  width: 4.64286rem; }

.width-70 {
  width: 5rem; }

.width-75 {
  width: 5.35714rem; }

.width-80 {
  width: 5.71429rem; }

.width-85 {
  width: 6.07143rem; }

.width-90 {
  width: 6.42857rem; }

.width-95 {
  width: 6.78571rem; }

.width-100 {
  width: 7.14286rem; }

.width-110 {
  width: 7.85714rem; }

.width-120 {
  width: 8.57143rem; }

.width-130 {
  width: 9.28571rem; }

.width-140 {
  width: 10rem; }

.width-150 {
  width: 10.71429rem; }

.width-160 {
  width: 11.42857rem; }

.width-170 {
  width: 12.14286rem; }

.width-180 {
  width: 12.85714rem; }

.width-190 {
  width: 13.57143rem; }

.width-200 {
  width: 14.28571rem; }

.width-210 {
  width: 15rem; }

.width-220 {
  width: 15.71429rem; }

.width-230 {
  width: 16.42857rem; }

.width-240 {
  width: 17.14286rem; }

.width-250 {
  width: 17.85714rem; }

.width-260 {
  width: 18.57143rem; }

.width-270 {
  width: 19.28571rem; }

.width-280 {
  width: 20rem; }

.width-290 {
  width: 20.71429rem; }

.width-300 {
  width: 21.42857rem; }

.width-310 {
  width: 22.14286rem; }

.width-320 {
  width: 22.85714rem; }

.width-330 {
  width: 23.57143rem; }

.width-340 {
  width: 24.28571rem; }

.width-350 {
  width: 25rem; }

.width-360 {
  width: 25.71429rem; }

.width-370 {
  width: 26.42857rem; }

.width-380 {
  width: 27.14286rem; }

.width-390 {
  width: 27.85714rem; }

.width-400 {
  width: 28.57143rem; }

.width-410 {
  width: 29.28571rem; }

.width-420 {
  width: 30rem; }

.width-430 {
  width: 30.71429rem; }

.width-440 {
  width: 31.42857rem; }

.width-450 {
  width: 32.14286rem; }

.width-460 {
  width: 32.85714rem; }

.width-470 {
  width: 33.57143rem; }

.width-480 {
  width: 34.28571rem; }

.width-490 {
  width: 35rem; }

.width-500 {
  width: 35.71429rem; }

.width-510 {
  width: 36.42857rem; }

.width-520 {
  width: 37.14286rem; }

.width-530 {
  width: 37.85714rem; }

.width-540 {
  width: 38.57143rem; }

.width-550 {
  width: 39.28571rem; }

.width-560 {
  width: 40rem; }

.width-570 {
  width: 40.71429rem; }

.width-580 {
  width: 41.42857rem; }

.width-590 {
  width: 42.14286rem; }

.width-600 {
  width: 42.85714rem; }

.width-610 {
  width: 43.57143rem; }

.width-620 {
  width: 44.28571rem; }

.width-630 {
  width: 45rem; }

.width-640 {
  width: 45.71429rem; }

.width-650 {
  width: 46.42857rem; }

.width-660 {
  width: 47.14286rem; }

.width-670 {
  width: 47.85714rem; }

.width-680 {
  width: 48.57143rem; }

.width-690 {
  width: 49.28571rem; }

.width-700 {
  width: 50rem; }

.width-710 {
  width: 50.71429rem; }

.width-720 {
  width: 51.42857rem; }

.width-730 {
  width: 52.14286rem; }

.width-740 {
  width: 52.85714rem; }

.width-750 {
  width: 53.57143rem; }

.width-760 {
  width: 54.28571rem; }

.width-770 {
  width: 55rem; }

.width-780 {
  width: 55.71429rem; }

.width-790 {
  width: 56.42857rem; }

.width-800 {
  width: 57.14286rem; }

.width-810 {
  width: 57.85714rem; }

.width-820 {
  width: 58.57143rem; }

.width-830 {
  width: 59.28571rem; }

.width-840 {
  width: 60rem; }

.width-850 {
  width: 60.71429rem; }

.width-860 {
  width: 61.42857rem; }

.width-870 {
  width: 62.14286rem; }

.width-880 {
  width: 62.85714rem; }

.width-890 {
  width: 63.57143rem; }

.width-900 {
  width: 64.28571rem; }

.width-910 {
  width: 65rem; }

.width-920 {
  width: 65.71429rem; }

.width-930 {
  width: 66.42857rem; }

.width-940 {
  width: 67.14286rem; }

.width-950 {
  width: 67.85714rem; }

.width-960 {
  width: 68.57143rem; }

.width-970 {
  width: 69.28571rem; }

.width-980 {
  width: 70rem; }

.width-990 {
  width: 70.71429rem; }

.width-1000 {
  width: 71.42857rem; }

.gap-wrapper {
  overflow: hidden; }

.h-gap-1 {
  margin-left: -0.03571rem;
  margin-right: -0.03571rem; }
  .h-gap-1 > * {
    padding-left: 0.03571rem;
    padding-right: 0.03571rem; }

.v-gap-1 {
  margin-top: -0.03571rem;
  margin-bottom: -0.03571rem; }
  .v-gap-1 > * {
    padding-top: 0.03571rem;
    padding-bottom: 0.03571rem; }

.h-gap-2 {
  margin-left: -0.07143rem;
  margin-right: -0.07143rem; }
  .h-gap-2 > * {
    padding-left: 0.07143rem;
    padding-right: 0.07143rem; }

.v-gap-2 {
  margin-top: -0.07143rem;
  margin-bottom: -0.07143rem; }
  .v-gap-2 > * {
    padding-top: 0.07143rem;
    padding-bottom: 0.07143rem; }

.h-gap-3 {
  margin-left: -0.10714rem;
  margin-right: -0.10714rem; }
  .h-gap-3 > * {
    padding-left: 0.10714rem;
    padding-right: 0.10714rem; }

.v-gap-3 {
  margin-top: -0.10714rem;
  margin-bottom: -0.10714rem; }
  .v-gap-3 > * {
    padding-top: 0.10714rem;
    padding-bottom: 0.10714rem; }

.h-gap-4 {
  margin-left: -0.14286rem;
  margin-right: -0.14286rem; }
  .h-gap-4 > * {
    padding-left: 0.14286rem;
    padding-right: 0.14286rem; }

.v-gap-4 {
  margin-top: -0.14286rem;
  margin-bottom: -0.14286rem; }
  .v-gap-4 > * {
    padding-top: 0.14286rem;
    padding-bottom: 0.14286rem; }

.h-gap-5 {
  margin-left: -0.17857rem;
  margin-right: -0.17857rem; }
  .h-gap-5 > * {
    padding-left: 0.17857rem;
    padding-right: 0.17857rem; }

.v-gap-5 {
  margin-top: -0.17857rem;
  margin-bottom: -0.17857rem; }
  .v-gap-5 > * {
    padding-top: 0.17857rem;
    padding-bottom: 0.17857rem; }

.h-gap-6 {
  margin-left: -0.21429rem;
  margin-right: -0.21429rem; }
  .h-gap-6 > * {
    padding-left: 0.21429rem;
    padding-right: 0.21429rem; }

.v-gap-6 {
  margin-top: -0.21429rem;
  margin-bottom: -0.21429rem; }
  .v-gap-6 > * {
    padding-top: 0.21429rem;
    padding-bottom: 0.21429rem; }

.h-gap-7 {
  margin-left: -0.25rem;
  margin-right: -0.25rem; }
  .h-gap-7 > * {
    padding-left: 0.25rem;
    padding-right: 0.25rem; }

.v-gap-7 {
  margin-top: -0.25rem;
  margin-bottom: -0.25rem; }
  .v-gap-7 > * {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem; }

.h-gap-8 {
  margin-left: -0.28571rem;
  margin-right: -0.28571rem; }
  .h-gap-8 > * {
    padding-left: 0.28571rem;
    padding-right: 0.28571rem; }

.v-gap-8 {
  margin-top: -0.28571rem;
  margin-bottom: -0.28571rem; }
  .v-gap-8 > * {
    padding-top: 0.28571rem;
    padding-bottom: 0.28571rem; }

.h-gap-9 {
  margin-left: -0.32143rem;
  margin-right: -0.32143rem; }
  .h-gap-9 > * {
    padding-left: 0.32143rem;
    padding-right: 0.32143rem; }

.v-gap-9 {
  margin-top: -0.32143rem;
  margin-bottom: -0.32143rem; }
  .v-gap-9 > * {
    padding-top: 0.32143rem;
    padding-bottom: 0.32143rem; }

.h-gap-10 {
  margin-left: -0.35714rem;
  margin-right: -0.35714rem; }
  .h-gap-10 > * {
    padding-left: 0.35714rem;
    padding-right: 0.35714rem; }

.v-gap-10 {
  margin-top: -0.35714rem;
  margin-bottom: -0.35714rem; }
  .v-gap-10 > * {
    padding-top: 0.35714rem;
    padding-bottom: 0.35714rem; }

.h-gap-11 {
  margin-left: -0.39286rem;
  margin-right: -0.39286rem; }
  .h-gap-11 > * {
    padding-left: 0.39286rem;
    padding-right: 0.39286rem; }

.v-gap-11 {
  margin-top: -0.39286rem;
  margin-bottom: -0.39286rem; }
  .v-gap-11 > * {
    padding-top: 0.39286rem;
    padding-bottom: 0.39286rem; }

.h-gap-12 {
  margin-left: -0.42857rem;
  margin-right: -0.42857rem; }
  .h-gap-12 > * {
    padding-left: 0.42857rem;
    padding-right: 0.42857rem; }

.v-gap-12 {
  margin-top: -0.42857rem;
  margin-bottom: -0.42857rem; }
  .v-gap-12 > * {
    padding-top: 0.42857rem;
    padding-bottom: 0.42857rem; }

.h-gap-13 {
  margin-left: -0.46429rem;
  margin-right: -0.46429rem; }
  .h-gap-13 > * {
    padding-left: 0.46429rem;
    padding-right: 0.46429rem; }

.v-gap-13 {
  margin-top: -0.46429rem;
  margin-bottom: -0.46429rem; }
  .v-gap-13 > * {
    padding-top: 0.46429rem;
    padding-bottom: 0.46429rem; }

.h-gap-14 {
  margin-left: -0.5rem;
  margin-right: -0.5rem; }
  .h-gap-14 > * {
    padding-left: 0.5rem;
    padding-right: 0.5rem; }

.v-gap-14 {
  margin-top: -0.5rem;
  margin-bottom: -0.5rem; }
  .v-gap-14 > * {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem; }

.h-gap-15 {
  margin-left: -0.53571rem;
  margin-right: -0.53571rem; }
  .h-gap-15 > * {
    padding-left: 0.53571rem;
    padding-right: 0.53571rem; }

.v-gap-15 {
  margin-top: -0.53571rem;
  margin-bottom: -0.53571rem; }
  .v-gap-15 > * {
    padding-top: 0.53571rem;
    padding-bottom: 0.53571rem; }

.h-gap-16 {
  margin-left: -0.57143rem;
  margin-right: -0.57143rem; }
  .h-gap-16 > * {
    padding-left: 0.57143rem;
    padding-right: 0.57143rem; }

.v-gap-16 {
  margin-top: -0.57143rem;
  margin-bottom: -0.57143rem; }
  .v-gap-16 > * {
    padding-top: 0.57143rem;
    padding-bottom: 0.57143rem; }

.h-gap-17 {
  margin-left: -0.60714rem;
  margin-right: -0.60714rem; }
  .h-gap-17 > * {
    padding-left: 0.60714rem;
    padding-right: 0.60714rem; }

.v-gap-17 {
  margin-top: -0.60714rem;
  margin-bottom: -0.60714rem; }
  .v-gap-17 > * {
    padding-top: 0.60714rem;
    padding-bottom: 0.60714rem; }

.h-gap-18 {
  margin-left: -0.64286rem;
  margin-right: -0.64286rem; }
  .h-gap-18 > * {
    padding-left: 0.64286rem;
    padding-right: 0.64286rem; }

.v-gap-18 {
  margin-top: -0.64286rem;
  margin-bottom: -0.64286rem; }
  .v-gap-18 > * {
    padding-top: 0.64286rem;
    padding-bottom: 0.64286rem; }

.h-gap-19 {
  margin-left: -0.67857rem;
  margin-right: -0.67857rem; }
  .h-gap-19 > * {
    padding-left: 0.67857rem;
    padding-right: 0.67857rem; }

.v-gap-19 {
  margin-top: -0.67857rem;
  margin-bottom: -0.67857rem; }
  .v-gap-19 > * {
    padding-top: 0.67857rem;
    padding-bottom: 0.67857rem; }

.h-gap-20 {
  margin-left: -0.71429rem;
  margin-right: -0.71429rem; }
  .h-gap-20 > * {
    padding-left: 0.71429rem;
    padding-right: 0.71429rem; }

.v-gap-20 {
  margin-top: -0.71429rem;
  margin-bottom: -0.71429rem; }
  .v-gap-20 > * {
    padding-top: 0.71429rem;
    padding-bottom: 0.71429rem; }

.h-gap-25 {
  margin-left: -0.89286rem;
  margin-right: -0.89286rem; }
  .h-gap-25 > * {
    padding-left: 0.89286rem;
    padding-right: 0.89286rem; }

.v-gap-25 {
  margin-top: -0.89286rem;
  margin-bottom: -0.89286rem; }
  .v-gap-25 > * {
    padding-top: 0.89286rem;
    padding-bottom: 0.89286rem; }

.h-gap-30 {
  margin-left: -1.07143rem;
  margin-right: -1.07143rem; }
  .h-gap-30 > * {
    padding-left: 1.07143rem;
    padding-right: 1.07143rem; }

.v-gap-30 {
  margin-top: -1.07143rem;
  margin-bottom: -1.07143rem; }
  .v-gap-30 > * {
    padding-top: 1.07143rem;
    padding-bottom: 1.07143rem; }

.h-gap-35 {
  margin-left: -1.25rem;
  margin-right: -1.25rem; }
  .h-gap-35 > * {
    padding-left: 1.25rem;
    padding-right: 1.25rem; }

.v-gap-35 {
  margin-top: -1.25rem;
  margin-bottom: -1.25rem; }
  .v-gap-35 > * {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem; }

.h-gap-40 {
  margin-left: -1.42857rem;
  margin-right: -1.42857rem; }
  .h-gap-40 > * {
    padding-left: 1.42857rem;
    padding-right: 1.42857rem; }

.v-gap-40 {
  margin-top: -1.42857rem;
  margin-bottom: -1.42857rem; }
  .v-gap-40 > * {
    padding-top: 1.42857rem;
    padding-bottom: 1.42857rem; }

.h-gap-45 {
  margin-left: -1.60714rem;
  margin-right: -1.60714rem; }
  .h-gap-45 > * {
    padding-left: 1.60714rem;
    padding-right: 1.60714rem; }

.v-gap-45 {
  margin-top: -1.60714rem;
  margin-bottom: -1.60714rem; }
  .v-gap-45 > * {
    padding-top: 1.60714rem;
    padding-bottom: 1.60714rem; }

.h-gap-50 {
  margin-left: -1.78571rem;
  margin-right: -1.78571rem; }
  .h-gap-50 > * {
    padding-left: 1.78571rem;
    padding-right: 1.78571rem; }

.v-gap-50 {
  margin-top: -1.78571rem;
  margin-bottom: -1.78571rem; }
  .v-gap-50 > * {
    padding-top: 1.78571rem;
    padding-bottom: 1.78571rem; }

.h-gap-60 {
  margin-left: -2.14286rem;
  margin-right: -2.14286rem; }
  .h-gap-60 > * {
    padding-left: 2.14286rem;
    padding-right: 2.14286rem; }

.v-gap-60 {
  margin-top: -2.14286rem;
  margin-bottom: -2.14286rem; }
  .v-gap-60 > * {
    padding-top: 2.14286rem;
    padding-bottom: 2.14286rem; }

.h-gap-70 {
  margin-left: -2.5rem;
  margin-right: -2.5rem; }
  .h-gap-70 > * {
    padding-left: 2.5rem;
    padding-right: 2.5rem; }

.v-gap-70 {
  margin-top: -2.5rem;
  margin-bottom: -2.5rem; }
  .v-gap-70 > * {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem; }

.h-gap-80 {
  margin-left: -2.85714rem;
  margin-right: -2.85714rem; }
  .h-gap-80 > * {
    padding-left: 2.85714rem;
    padding-right: 2.85714rem; }

.v-gap-80 {
  margin-top: -2.85714rem;
  margin-bottom: -2.85714rem; }
  .v-gap-80 > * {
    padding-top: 2.85714rem;
    padding-bottom: 2.85714rem; }

.h-gap-90 {
  margin-left: -3.21429rem;
  margin-right: -3.21429rem; }
  .h-gap-90 > * {
    padding-left: 3.21429rem;
    padding-right: 3.21429rem; }

.v-gap-90 {
  margin-top: -3.21429rem;
  margin-bottom: -3.21429rem; }
  .v-gap-90 > * {
    padding-top: 3.21429rem;
    padding-bottom: 3.21429rem; }

.h-gap-100 {
  margin-left: -3.57143rem;
  margin-right: -3.57143rem; }
  .h-gap-100 > * {
    padding-left: 3.57143rem;
    padding-right: 3.57143rem; }

.v-gap-100 {
  margin-top: -3.57143rem;
  margin-bottom: -3.57143rem; }
  .v-gap-100 > * {
    padding-top: 3.57143rem;
    padding-bottom: 3.57143rem; }

.h-gap-section {
  margin-left: -1.42857rem;
  margin-right: -1.42857rem; }
  .h-gap-section > * {
    padding-left: 1.42857rem;
    padding-right: 1.42857rem; }

.v-gap-section {
  margin-top: -1.42857rem;
  margin-bottom: -1.42857rem; }
  .v-gap-section > * {
    padding-top: 1.42857rem;
    padding-bottom: 1.42857rem; }

.h-gap-large {
  margin-left: -1.07143rem;
  margin-right: -1.07143rem; }
  .h-gap-large > * {
    padding-left: 1.07143rem;
    padding-right: 1.07143rem; }

.v-gap-large {
  margin-top: -1.07143rem;
  margin-bottom: -1.07143rem; }
  .v-gap-large > * {
    padding-top: 1.07143rem;
    padding-bottom: 1.07143rem; }

.h-gap-default {
  margin-left: -0.71429rem;
  margin-right: -0.71429rem; }
  .h-gap-default > * {
    padding-left: 0.71429rem;
    padding-right: 0.71429rem; }

.v-gap-default {
  margin-top: -0.71429rem;
  margin-bottom: -0.71429rem; }
  .v-gap-default > * {
    padding-top: 0.71429rem;
    padding-bottom: 0.71429rem; }

.h-gap-small {
  margin-left: -0.35714rem;
  margin-right: -0.35714rem; }
  .h-gap-small > * {
    padding-left: 0.35714rem;
    padding-right: 0.35714rem; }

.v-gap-small {
  margin-top: -0.35714rem;
  margin-bottom: -0.35714rem; }
  .v-gap-small > * {
    padding-top: 0.35714rem;
    padding-bottom: 0.35714rem; }

.h-gap-mini {
  margin-left: -0.17857rem;
  margin-right: -0.17857rem; }
  .h-gap-mini > * {
    padding-left: 0.17857rem;
    padding-right: 0.17857rem; }

.v-gap-mini {
  margin-top: -0.17857rem;
  margin-bottom: -0.17857rem; }
  .v-gap-mini > * {
    padding-top: 0.17857rem;
    padding-bottom: 0.17857rem; }

.push-1 {
  height: 0.07143rem; }

.push-2 {
  height: 0.14286rem; }

.push-3 {
  height: 0.21429rem; }

.push-4 {
  height: 0.28571rem; }

.push-5 {
  height: 0.35714rem; }

.push-6 {
  height: 0.42857rem; }

.push-7 {
  height: 0.5rem; }

.push-8 {
  height: 0.57143rem; }

.push-9 {
  height: 0.64286rem; }

.push-10 {
  height: 0.71429rem; }

.push-11 {
  height: 0.78571rem; }

.push-12 {
  height: 0.85714rem; }

.push-13 {
  height: 0.92857rem; }

.push-14 {
  height: 1rem; }

.push-15 {
  height: 1.07143rem; }

.push-16 {
  height: 1.14286rem; }

.push-17 {
  height: 1.21429rem; }

.push-18 {
  height: 1.28571rem; }

.push-19 {
  height: 1.35714rem; }

.push-20 {
  height: 1.42857rem; }

.push-25 {
  height: 1.78571rem; }

.push-30 {
  height: 2.14286rem; }

.push-35 {
  height: 2.5rem; }

.push-40 {
  height: 2.85714rem; }

.push-45 {
  height: 3.21429rem; }

.push-50 {
  height: 3.57143rem; }

.push-55 {
  height: 3.92857rem; }

.push-60 {
  height: 4.28571rem; }

.push-65 {
  height: 4.64286rem; }

.push-70 {
  height: 5rem; }

.push-75 {
  height: 5.35714rem; }

.push-80 {
  height: 5.71429rem; }

.push-85 {
  height: 6.07143rem; }

.push-90 {
  height: 6.42857rem; }

.push-95 {
  height: 6.78571rem; }

.push-100 {
  height: 7.14286rem; }

.push-110 {
  height: 7.85714rem; }

.push-120 {
  height: 8.57143rem; }

.push-130 {
  height: 9.28571rem; }

.push-140 {
  height: 10rem; }

.push-150 {
  height: 10.71429rem; }

.push-160 {
  height: 11.42857rem; }

.push-170 {
  height: 12.14286rem; }

.push-180 {
  height: 12.85714rem; }

.push-190 {
  height: 13.57143rem; }

.push-200 {
  height: 14.28571rem; }

.push-250 {
  height: 17.85714rem; }

.push-300 {
  height: 21.42857rem; }

.push-350 {
  height: 25rem; }

.push-400 {
  height: 28.57143rem; }

.push-450 {
  height: 32.14286rem; }

.push-500 {
  height: 35.71429rem; }

.push-section {
  height: 2.85714rem; }

.push-large {
  height: 2.14286rem; }

.push-default {
  height: 1.42857rem; }

.push-small {
  height: 0.71429rem; }

.push-mini {
  height: 0.35714rem; }

.pull-1 {
  margin-bottom: -0.07143rem; }

.pull-2 {
  margin-bottom: -0.14286rem; }

.pull-3 {
  margin-bottom: -0.21429rem; }

.pull-4 {
  margin-bottom: -0.28571rem; }

.pull-5 {
  margin-bottom: -0.35714rem; }

.pull-6 {
  margin-bottom: -0.42857rem; }

.pull-7 {
  margin-bottom: -0.5rem; }

.pull-8 {
  margin-bottom: -0.57143rem; }

.pull-9 {
  margin-bottom: -0.64286rem; }

.pull-10 {
  margin-bottom: -0.71429rem; }

.pull-11 {
  margin-bottom: -0.78571rem; }

.pull-12 {
  margin-bottom: -0.85714rem; }

.pull-13 {
  margin-bottom: -0.92857rem; }

.pull-14 {
  margin-bottom: -1rem; }

.pull-15 {
  margin-bottom: -1.07143rem; }

.pull-16 {
  margin-bottom: -1.14286rem; }

.pull-17 {
  margin-bottom: -1.21429rem; }

.pull-18 {
  margin-bottom: -1.28571rem; }

.pull-19 {
  margin-bottom: -1.35714rem; }

.pull-20 {
  margin-bottom: -1.42857rem; }

.pull-25 {
  margin-bottom: -1.78571rem; }

.pull-30 {
  margin-bottom: -2.14286rem; }

.pull-35 {
  margin-bottom: -2.5rem; }

.pull-40 {
  margin-bottom: -2.85714rem; }

.pull-45 {
  margin-bottom: -3.21429rem; }

.pull-50 {
  margin-bottom: -3.57143rem; }

.pull-section {
  margin-bottom: -2.85714rem; }

.pull-large {
  margin-bottom: -2.14286rem; }

.pull-default {
  margin-bottom: -1.42857rem; }

.pull-small {
  margin-bottom: -0.71429rem; }

.pull-mini {
  margin-bottom: -0.35714rem; }

@media (max-width: 1000px) {
  .hide\@mini-laptop {
    display: none !important; } }

@media (min-width: 1001px) {
  .show\@mini-laptop {
    display: none !important; } }

@media (max-width: 900px) {
  .hide\@tablet {
    display: none !important; } }

@media (min-width: 901px) {
  .show\@tablet {
    display: none !important; } }

@media (max-width: 800px) {
  .hide\@medium-tablet {
    display: none !important; } }

@media (min-width: 801px) {
  .show\@medium-tablet {
    display: none !important; } }

@media (max-width: 700px) {
  .hide\@small-tablet {
    display: none !important; } }

@media (min-width: 701px) {
  .show\@small-tablet {
    display: none !important; } }

@media (max-width: 600px) {
  .hide\@mini-tablet {
    display: none !important; } }

@media (min-width: 601px) {
  .show\@mini-tablet {
    display: none !important; } }

@media (max-width: 500px) {
  .hide\@mobile {
    display: none !important; } }

@media (min-width: 501px) {
  .show\@mobile {
    display: none !important; } }

@media (max-width: 440px) {
  .hide\@medium-mobile {
    display: none !important; } }

@media (min-width: 441px) {
  .show\@medium-mobile {
    display: none !important; } }

@media (max-width: 380px) {
  .hide\@small-mobile {
    display: none !important; } }

@media (min-width: 381px) {
  .show\@small-mobile {
    display: none !important; } }

@media (max-width: 320px) {
  .hide\@mini-mobile {
    display: none !important; } }

@media (min-width: 321px) {
  .show\@mini-mobile {
    display: none !important; } }

@media (min-width: 1201px) {
  .hide\@medium-laptop {
    display: none !important; } }

@media (max-width: 1200px) {
  .show\@medium-laptop {
    display: none !important; } }

@media (min-width: 1301px) {
  .hide\@large-laptop {
    display: none !important; } }

@media (max-width: 1300px) {
  .show\@large-laptop {
    display: none !important; } }

@media (min-width: 1401px) {
  .hide\@desktop {
    display: none !important; } }

@media (max-width: 1400px) {
  .show\@desktop {
    display: none !important; } }

@media (min-width: 1501px) {
  .hide\@small-desktop {
    display: none !important; } }

@media (max-width: 1500px) {
  .show\@small-desktop {
    display: none !important; } }

@media (min-width: 1601px) {
  .hide\@medium-desktop {
    display: none !important; } }

@media (max-width: 1600px) {
  .show\@medium-desktop {
    display: none !important; } }

@media (min-width: 1701px) {
  .hide\@large-desktop {
    display: none !important; } }

@media (max-width: 1700px) {
  .show\@large-desktop {
    display: none !important; } }

.rich-text h1 + h1, .rich-text h1 + h2, .rich-text h1 + h3, .rich-text h1 + h4, .rich-text h1 + h5, .rich-text h1 + h6, .rich-text h2 + h1, .rich-text h2 + h2, .rich-text h2 + h3, .rich-text h2 + h4, .rich-text h2 + h5, .rich-text h2 + h6, .rich-text h3 + h1, .rich-text h3 + h2, .rich-text h3 + h3, .rich-text h3 + h4, .rich-text h3 + h5, .rich-text h3 + h6, .rich-text h4 + h1, .rich-text h4 + h2, .rich-text h4 + h3, .rich-text h4 + h4, .rich-text h4 + h5, .rich-text h4 + h6, .rich-text h5 + h1, .rich-text h5 + h2, .rich-text h5 + h3, .rich-text h5 + h4, .rich-text h5 + h5, .rich-text h5 + h6, .rich-text h6 + h1, .rich-text h6 + h2, .rich-text h6 + h3, .rich-text h6 + h4, .rich-text h6 + h5, .rich-text h6 + h6 {
  margin-top: 1.81818vw; }
  @media (max-width: 1100px) {
    .rich-text h1 + h1, .rich-text h1 + h2, .rich-text h1 + h3, .rich-text h1 + h4, .rich-text h1 + h5, .rich-text h1 + h6, .rich-text h2 + h1, .rich-text h2 + h2, .rich-text h2 + h3, .rich-text h2 + h4, .rich-text h2 + h5, .rich-text h2 + h6, .rich-text h3 + h1, .rich-text h3 + h2, .rich-text h3 + h3, .rich-text h3 + h4, .rich-text h3 + h5, .rich-text h3 + h6, .rich-text h4 + h1, .rich-text h4 + h2, .rich-text h4 + h3, .rich-text h4 + h4, .rich-text h4 + h5, .rich-text h4 + h6, .rich-text h5 + h1, .rich-text h5 + h2, .rich-text h5 + h3, .rich-text h5 + h4, .rich-text h5 + h5, .rich-text h5 + h6, .rich-text h6 + h1, .rich-text h6 + h2, .rich-text h6 + h3, .rich-text h6 + h4, .rich-text h6 + h5, .rich-text h6 + h6 {
      margin-top: 20px; } }
  @media (min-width: 1501px) {
    .rich-text h1 + h1, .rich-text h1 + h2, .rich-text h1 + h3, .rich-text h1 + h4, .rich-text h1 + h5, .rich-text h1 + h6, .rich-text h2 + h1, .rich-text h2 + h2, .rich-text h2 + h3, .rich-text h2 + h4, .rich-text h2 + h5, .rich-text h2 + h6, .rich-text h3 + h1, .rich-text h3 + h2, .rich-text h3 + h3, .rich-text h3 + h4, .rich-text h3 + h5, .rich-text h3 + h6, .rich-text h4 + h1, .rich-text h4 + h2, .rich-text h4 + h3, .rich-text h4 + h4, .rich-text h4 + h5, .rich-text h4 + h6, .rich-text h5 + h1, .rich-text h5 + h2, .rich-text h5 + h3, .rich-text h5 + h4, .rich-text h5 + h5, .rich-text h5 + h6, .rich-text h6 + h1, .rich-text h6 + h2, .rich-text h6 + h3, .rich-text h6 + h4, .rich-text h6 + h5, .rich-text h6 + h6 {
      margin-top: 27.27273px; } }

.rich-text h1 + p, .rich-text h1 + ul, .rich-text h1 + ol, .rich-text h2 + p, .rich-text h2 + ul, .rich-text h2 + ol, .rich-text h3 + p, .rich-text h3 + ul, .rich-text h3 + ol, .rich-text h4 + p, .rich-text h4 + ul, .rich-text h4 + ol, .rich-text h5 + p, .rich-text h5 + ul, .rich-text h5 + ol, .rich-text h6 + p, .rich-text h6 + ul, .rich-text h6 + ol {
  margin-top: 1.81818vw; }
  @media (max-width: 1100px) {
    .rich-text h1 + p, .rich-text h1 + ul, .rich-text h1 + ol, .rich-text h2 + p, .rich-text h2 + ul, .rich-text h2 + ol, .rich-text h3 + p, .rich-text h3 + ul, .rich-text h3 + ol, .rich-text h4 + p, .rich-text h4 + ul, .rich-text h4 + ol, .rich-text h5 + p, .rich-text h5 + ul, .rich-text h5 + ol, .rich-text h6 + p, .rich-text h6 + ul, .rich-text h6 + ol {
      margin-top: 20px; } }
  @media (min-width: 1501px) {
    .rich-text h1 + p, .rich-text h1 + ul, .rich-text h1 + ol, .rich-text h2 + p, .rich-text h2 + ul, .rich-text h2 + ol, .rich-text h3 + p, .rich-text h3 + ul, .rich-text h3 + ol, .rich-text h4 + p, .rich-text h4 + ul, .rich-text h4 + ol, .rich-text h5 + p, .rich-text h5 + ul, .rich-text h5 + ol, .rich-text h6 + p, .rich-text h6 + ul, .rich-text h6 + ol {
      margin-top: 27.27273px; } }

.rich-text p + p, .rich-text p + ul, .rich-text p + ol, .rich-text ul + p, .rich-text ul + ul, .rich-text ul + ol, .rich-text ol + p, .rich-text ol + ul, .rich-text ol + ol {
  margin-top: 1.81818vw; }
  @media (max-width: 1100px) {
    .rich-text p + p, .rich-text p + ul, .rich-text p + ol, .rich-text ul + p, .rich-text ul + ul, .rich-text ul + ol, .rich-text ol + p, .rich-text ol + ul, .rich-text ol + ol {
      margin-top: 20px; } }
  @media (min-width: 1501px) {
    .rich-text p + p, .rich-text p + ul, .rich-text p + ol, .rich-text ul + p, .rich-text ul + ul, .rich-text ul + ol, .rich-text ol + p, .rich-text ol + ul, .rich-text ol + ol {
      margin-top: 27.27273px; } }

.rich-text p + h1, .rich-text p + h2, .rich-text p + h3, .rich-text p + h4, .rich-text p + h5, .rich-text p + h6, .rich-text ul + h1, .rich-text ul + h2, .rich-text ul + h3, .rich-text ul + h4, .rich-text ul + h5, .rich-text ul + h6, .rich-text ol + h1, .rich-text ol + h2, .rich-text ol + h3, .rich-text ol + h4, .rich-text ol + h5, .rich-text ol + h6 {
  margin-top: 2.72727vw; }
  @media (max-width: 1100px) {
    .rich-text p + h1, .rich-text p + h2, .rich-text p + h3, .rich-text p + h4, .rich-text p + h5, .rich-text p + h6, .rich-text ul + h1, .rich-text ul + h2, .rich-text ul + h3, .rich-text ul + h4, .rich-text ul + h5, .rich-text ul + h6, .rich-text ol + h1, .rich-text ol + h2, .rich-text ol + h3, .rich-text ol + h4, .rich-text ol + h5, .rich-text ol + h6 {
      margin-top: 30px; } }
  @media (min-width: 1501px) {
    .rich-text p + h1, .rich-text p + h2, .rich-text p + h3, .rich-text p + h4, .rich-text p + h5, .rich-text p + h6, .rich-text ul + h1, .rich-text ul + h2, .rich-text ul + h3, .rich-text ul + h4, .rich-text ul + h5, .rich-text ul + h6, .rich-text ol + h1, .rich-text ol + h2, .rich-text ol + h3, .rich-text ol + h4, .rich-text ol + h5, .rich-text ol + h6 {
      margin-top: 40.90909px; } }

.rich-text ul, .rich-text ol {
  margin-left: 2.27273vw; }
  @media (max-width: 1100px) {
    .rich-text ul, .rich-text ol {
      margin-left: 25px; } }
  @media (min-width: 1501px) {
    .rich-text ul, .rich-text ol {
      margin-left: 34.09091px; } }

.rich-text li:not(:last-child) {
  padding-bottom: 0; }
  @media (max-width: 1100px) {
    .rich-text li:not(:last-child) {
      padding-bottom: 0; } }
  @media (min-width: 1501px) {
    .rich-text li:not(:last-child) {
      padding-bottom: 0; } }

.rich-text h1 {
  font-size: 3.63636vw;
  line-height: 1; }
  @media (max-width: 900px) {
    .rich-text h1 {
      font-size: 32.72727px; } }
  @media (min-width: 1501px) {
    .rich-text h1 {
      font-size: 54.54545px; } }

.rich-text h2 {
  font-size: 1.81818vw;
  font-weight: 400; }
  @media (max-width: 1000px) {
    .rich-text h2 {
      font-size: 18.18182px; } }
  @media (min-width: 1501px) {
    .rich-text h2 {
      font-size: 27.27273px; } }

.rich-text h3 {
  font-size: 1.45455vw;
  font-weight: 400; }
  @media (max-width: 1100px) {
    .rich-text h3 {
      font-size: 16px; } }
  @media (min-width: 1501px) {
    .rich-text h3 {
      font-size: 21.81818px; } }

.h1 {
  font-size: 3.63636vw;
  line-height: 1; }
  @media (max-width: 900px) {
    .h1 {
      font-size: 32.72727px; } }
  @media (min-width: 1501px) {
    .h1 {
      font-size: 54.54545px; } }

.h2 {
  font-size: 1.81818vw;
  font-weight: 400; }
  @media (max-width: 1000px) {
    .h2 {
      font-size: 18.18182px; } }
  @media (min-width: 1501px) {
    .h2 {
      font-size: 27.27273px; } }

.h3 {
  font-size: 1.45455vw;
  font-weight: 400; }
  @media (max-width: 1100px) {
    .h3 {
      font-size: 16px; } }
  @media (min-width: 1501px) {
    .h3 {
      font-size: 21.81818px; } }

.p {
  font-size: 1.27273vw;
  font-weight: 400; }
  @media (max-width: 1100px) {
    .p {
      font-size: 14px; } }
  @media (min-width: 1501px) {
    .p {
      font-size: 19.09091px; } }

.font-default {
  font-family: "Josefin Sans", sans-serif; }

.size-h1 {
  font-size: 3.63636vw; }
  @media (max-width: 1100px) {
    .size-h1 {
      font-size: 40px; } }
  @media (min-width: 1501px) {
    .size-h1 {
      font-size: 54.54545px; } }

.size-h1-small {
  font-size: 2.72727vw; }
  @media (max-width: 1100px) {
    .size-h1-small {
      font-size: 30px; } }
  @media (min-width: 1501px) {
    .size-h1-small {
      font-size: 40.90909px; } }

.size-h2 {
  font-size: 1.81818vw; }
  @media (max-width: 1100px) {
    .size-h2 {
      font-size: 20px; } }
  @media (min-width: 1501px) {
    .size-h2 {
      font-size: 27.27273px; } }

.size-h3 {
  font-size: 1.45455vw; }
  @media (max-width: 1100px) {
    .size-h3 {
      font-size: 16px; } }
  @media (min-width: 1501px) {
    .size-h3 {
      font-size: 21.81818px; } }

.size-default {
  font-size: 1.27273vw; }
  @media (max-width: 1100px) {
    .size-default {
      font-size: 14px; } }
  @media (min-width: 1501px) {
    .size-default {
      font-size: 19.09091px; } }

.size-small {
  font-size: 1.09091vw; }
  @media (max-width: 1100px) {
    .size-small {
      font-size: 12px; } }
  @media (min-width: 1501px) {
    .size-small {
      font-size: 16.36364px; } }

.weight-light {
  font-weight: 300; }

.weight-regular {
  font-weight: 400; }

.weight-bold {
  font-weight: 700; }

.color-orange {
  color: #dc911b; }

a.color-orange:hover {
  color: #ebb25a; }

.bg-orange {
  background-color: #dc911b; }

.color-orange-dark {
  color: #cb4f1c; }

a.color-orange-dark:hover {
  color: #e77b4f; }

.bg-orange-dark {
  background-color: #cb4f1c; }

.color-red {
  color: #c20e1a; }

a.color-red:hover {
  color: #f0313e; }

.bg-red {
  background-color: #c20e1a; }

.color-turquoise {
  color: #1aa8a3; }

a.color-turquoise:hover {
  color: #35e0da; }

.bg-turquoise {
  background-color: #1aa8a3; }

.line-height-tight {
  line-height: 1.2; }

.line-height-default {
  line-height: 1.5; }

.align-left {
  text-align: left; }

.align-center {
  text-align: center; }

.align-right {
  text-align: right; }

.align-justify {
  text-align: justify; }

@media (max-width: 1000px) {
  .align-left\@mini-laptop {
    text-align: left; }
  .align-center\@mini-laptop {
    text-align: center; }
  .align-right\@mini-laptop {
    text-align: right; }
  .align-justify\@mini-laptop {
    text-align: justify; } }

@media (max-width: 900px) {
  .align-left\@tablet {
    text-align: left; }
  .align-center\@tablet {
    text-align: center; }
  .align-right\@tablet {
    text-align: right; }
  .align-justify\@tablet {
    text-align: justify; } }

@media (max-width: 800px) {
  .align-left\@medium-tablet {
    text-align: left; }
  .align-center\@medium-tablet {
    text-align: center; }
  .align-right\@medium-tablet {
    text-align: right; }
  .align-justify\@medium-tablet {
    text-align: justify; } }

@media (max-width: 700px) {
  .align-left\@small-tablet {
    text-align: left; }
  .align-center\@small-tablet {
    text-align: center; }
  .align-right\@small-tablet {
    text-align: right; }
  .align-justify\@small-tablet {
    text-align: justify; } }

@media (max-width: 600px) {
  .align-left\@mini-tablet {
    text-align: left; }
  .align-center\@mini-tablet {
    text-align: center; }
  .align-right\@mini-tablet {
    text-align: right; }
  .align-justify\@mini-tablet {
    text-align: justify; } }

@media (max-width: 500px) {
  .align-left\@mobile {
    text-align: left; }
  .align-center\@mobile {
    text-align: center; }
  .align-right\@mobile {
    text-align: right; }
  .align-justify\@mobile {
    text-align: justify; } }

@media (max-width: 440px) {
  .align-left\@medium-mobile {
    text-align: left; }
  .align-center\@medium-mobile {
    text-align: center; }
  .align-right\@medium-mobile {
    text-align: right; }
  .align-justify\@medium-mobile {
    text-align: justify; } }

@media (max-width: 380px) {
  .align-left\@small-mobile {
    text-align: left; }
  .align-center\@small-mobile {
    text-align: center; }
  .align-right\@small-mobile {
    text-align: right; }
  .align-justify\@small-mobile {
    text-align: justify; } }

@media (max-width: 320px) {
  .align-left\@mini-mobile {
    text-align: left; }
  .align-center\@mini-mobile {
    text-align: center; }
  .align-right\@mini-mobile {
    text-align: right; }
  .align-justify\@mini-mobile {
    text-align: justify; } }

.italic {
  font-style: italic; }

.underline {
  text-decoration: underline; }

.uppercase {
  text-transform: uppercase; }

.capitalize {
  text-transform: capitalize; }

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis; }

.no-wrap {
  white-space: nowrap; }

.hyphens {
  hyphens: auto; }

@media (max-width: 1000px) {
  .hyphens\@mini-laptop {
    hyphens: auto; } }

@media (max-width: 900px) {
  .hyphens\@tablet {
    hyphens: auto; } }

@media (max-width: 800px) {
  .hyphens\@medium-tablet {
    hyphens: auto; } }

@media (max-width: 700px) {
  .hyphens\@small-tablet {
    hyphens: auto; } }

@media (max-width: 600px) {
  .hyphens\@mini-tablet {
    hyphens: auto; } }

@media (max-width: 500px) {
  .hyphens\@mobile {
    hyphens: auto; } }

@media (max-width: 440px) {
  .hyphens\@medium-mobile {
    hyphens: auto; } }

@media (max-width: 380px) {
  .hyphens\@small-mobile {
    hyphens: auto; } }

@media (max-width: 320px) {
  .hyphens\@mini-mobile {
    hyphens: auto; } }

img.svg-img {
  visibility: hidden; }

.svg-img-ratio {
  pointer-events: none; }

.svg-img-data {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0; }
  .svg-img-data svg {
    width: 100%;
    height: 100%; }

.background {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden; }
  .background.is-size-contain {
    background-size: contain; }
  .background.is-size-auto {
    background-size: auto; }
  .background.is-repeat {
    background-repeat: repeat; }

.background-video > video::-webkit-media-controls-play-button, .background-video > video::-webkit-media-controls-start-playback-button {
  width: 5px;
  opacity: 0;
  pointer-events: none; }

.background-video.is-size-cover {
  min-height: calc(100% + 2px);
  position: absolute;
  left: -1px;
  right: -1px; }
  .background-video.is-size-cover::before {
    content: "";
    display: block;
    padding-bottom: 56.25%; }
  .background-video.is-size-cover > video {
    width: auto;
    max-width: none;
    height: 100%;
    position: absolute;
    top: 0; }
  .background-video.is-size-cover.is-align-left > video {
    left: 0; }
  .background-video.is-size-cover.is-align-center > video {
    left: 50%;
    top: 0;
    transform: translate(-50%, 0%); }
  .background-video.is-size-cover.is-align-right > video {
    right: 0; }
  .background-video.is-size-cover.is-align-top {
    top: 0; }
  .background-video.is-size-cover.is-align-middle {
    top: 50%;
    transform: translate(0%, -50%); }
  .background-video.is-size-cover.is-align-bottom {
    bottom: 0; }

.background-video.is-size-contain {
  position: absolute;
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px; }
  .background-video.is-size-contain > video {
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    position: absolute; }
  .background-video.is-size-contain.is-fill-width > video {
    width: 100%;
    max-height: none; }
  .background-video.is-size-contain.is-fill-height {
    height: auto; }
    .background-video.is-size-contain.is-fill-height > video {
      max-width: none;
      height: 100%; }
  .background-video.is-size-contain.is-align-left > video {
    left: 0; }
  .background-video.is-size-contain.is-align-center > video {
    left: 0;
    right: 0;
    margin: auto; }
  .background-video.is-size-contain.is-align-right > video {
    right: 0; }
  .background-video.is-size-contain.is-align-top > video {
    top: 0; }
  .background-video.is-size-contain.is-align-middle > video {
    top: 0;
    bottom: 0;
    margin: auto; }
  .background-video.is-size-contain.is-align-bottom > video {
    bottom: 0; }

.focuspoint img {
  opacity: 0; }

.focuspoint.ready img {
  opacity: 1; }

/* TYPOGRAPHY */
.h1 {
  padding-bottom: 3.42857rem; }
  .h1 small {
    display: block;
    font-size: 2.72727vw;
    line-height: 1.5; }
    @media (max-width: 900px) {
      .h1 small {
        font-size: 24.54545px; } }
    @media (min-width: 1501px) {
      .h1 small {
        font-size: 40.90909px; } }
  .h1::after {
    content: "";
    display: block;
    width: 714.28571rem;
    height: 1.28571rem;
    position: absolute;
    right: calc(100% - 11.42857rem);
    bottom: 0;
    background-image: url("../images/color-bar.png");
    background-position: center right;
    background-repeat: no-repeat;
    background-size: 10% 100%;
    background-color: #cb4f1c; }
  .h1.align-right {
    display: inline-block;
    vertical-align: top; }
    @media (min-width: 381px) {
      .h1.align-right {
        min-width: 25.35714rem; } }
    .h1.align-right::after {
      right: 0; }
  .h1.right {
    text-align: right; }
    .h1.right::after {
      right: auto;
      left: calc(100% - 11.42857rem);
      background-position: center left; }
  .h1.black-bar::after {
    background-image: none;
    background-color: black;
    right: calc(100% - 16.07143rem); }
  .h1.no-bar {
    padding-bottom: 0; }
    .h1.no-bar::after {
      display: none; }

/* BUTTONS AND DROPDOWNS */
.button {
  display: inline-block;
  vertical-align: top;
  width: auto;
  padding: 0.85714rem 1.14286rem;
  border: 0.14286rem solid black;
  background-color: rgba(255, 255, 255, 0);
  color: black;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: border-color 0.25s, background-color 0.25s, color 0.25s; }
  .button:hover {
    border-color: #dc911b;
    color: #dc911b; }
  .button:active {
    transition: none; }
  .button.small {
    padding: 0.57143rem 0.85714rem;
    font-size: 1.09091vw; }
    @media (max-width: 1100px) {
      .button.small {
        font-size: 12px; } }
    @media (min-width: 1501px) {
      .button.small {
        font-size: 16.36364px; } }
  .button.filled {
    padding: 1rem 1.28571rem;
    border: none;
    color: white;
    background-color: black; }
    .button.filled:hover {
      background-color: #1a1a1a; }
    .button.filled.small {
      padding: 0.71429rem 1rem; }
    .button.filled.orange {
      background-color: #dc911b; }
      .button.filled.orange:hover {
        background-color: #e6a237; }
    .button.filled.orange-dark {
      background-color: #cb4f1c; }
      .button.filled.orange-dark:hover {
        background-color: #e2612c; }
    .button.filled.red {
      background-color: #c20e1a; }
      .button.filled.red:hover {
        background-color: #e61221; }
    .button.filled.turquoise {
      background-color: #1aa8a3; }
      .button.filled.turquoise:hover {
        background-color: #20ccc6; }

.dropdown {
  display: inline-block;
  vertical-align: top;
  max-width: 100%; }
  .dropdown .button {
    display: block;
    padding-left: 2.85714rem;
    padding-right: 3.35714rem; }
    .dropdown .button .svg-img {
      width: 1.78571rem;
      position: absolute;
      right: 0.85714rem;
      top: 0;
      bottom: 0; }
    .dropdown .button .dropdown-arrow-up {
      display: none; }
  .dropdown .dropdown-menu {
    display: none;
    border: 0.14286rem solid black;
    border-top-width: 0;
    background-color: rgba(255, 255, 255, 0);
    transition: border-color 0.25s, background-color 0.25s; }
    .dropdown .dropdown-menu > ul {
      padding: 0.21429rem 0; }
      .dropdown .dropdown-menu > ul > li {
        display: block; }
        .dropdown .dropdown-menu > ul > li > a {
          display: block;
          height: 2.85714rem;
          margin: 0 -1px;
          font-size: 1.27273vw;
          color: black;
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap; }
          @media (max-width: 1100px) {
            .dropdown .dropdown-menu > ul > li > a {
              font-size: 14px; } }
          @media (min-width: 1501px) {
            .dropdown .dropdown-menu > ul > li > a {
              font-size: 19.09091px; } }
          .dropdown .dropdown-menu > ul > li > a .dropdown-option-title {
            width: 100%;
            position: absolute;
            top: 50%;
            transform: translate(0, -50%); }
          .dropdown .dropdown-menu > ul > li > a:hover {
            color: #dc911b; }
          .dropdown .dropdown-menu > ul > li > a:active {
            background-color: #dc911b;
            color: white; }
  .dropdown.open .button .dropdown-arrow-down {
    display: none; }
  .dropdown.open .button .dropdown-arrow-up {
    display: block; }
  .dropdown:hover .button, .dropdown:hover .dropdown-menu {
    border-color: #dc911b;
    background-color: white; }

/* CONTAINERS */
.bg-dark {
  background-color: black;
  color: white; }

.section-header img {
  width: 100%; }

/* BACKGROUNDS */
.background-triangle::before {
  content: "";
  display: block;
  width: 100%;
  position: absolute;
  bottom: -100vw;
  border: 100vw solid transparent; }

.background-triangle-productions::before {
  right: 0;
  border-right-color: #1aa8a3; }

.background-triangle-team::before {
  left: 0;
  border-left-color: #dc911b; }

body {
  background-color: black; }

header {
  width: 100%;
  height: 8rem; }
  header.fixed {
    position: fixed;
    left: 0;
    top: 0;
    z-index: 100; }
  header > .background {
    background-color: black; }
  header > .container {
    height: 100%; }
    header > .container > .inner {
      height: 100%; }
  header a {
    color: white; }
    header a:hover {
      color: #dc911b; }
  header .logo {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1; }
    header .logo > a {
      display: block;
      width: 7.28571rem;
      position: absolute;
      left: 0;
      top: 1rem; }
  header nav {
    color: white; }
    header nav ul > li {
      display: inline-block;
      vertical-align: top;
      height: 100%; }
      header nav ul > li > a {
        display: block;
        height: 100%; }
    header nav ul.social .svg-img {
      top: 50%;
      transform: translate(0, -50%); }
  header a.hamburger {
    display: none;
    width: 55px;
    height: 55px;
    padding: 10px;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    right: -5px;
    z-index: 1; }
    @media (max-width: 900px) {
      header a.hamburger {
        display: block; } }
    header a.hamburger .close-nav {
      display: none; }
  header.light > .background {
    background-color: white; }
  header.light a {
    color: black; }
    header.light a:hover {
      color: #dc911b; }
  header.light nav {
    color: black; }
  header.featured > .background {
    opacity: 0; }
  header.featured .logo {
    transform: scale(1.53); }
  @media (min-width: 901px) {
    header .hamburger-show {
      display: none; }
    header nav {
      height: 100%; }
      header nav > .inner {
        width: 100%;
        height: 2.14286rem;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%); }
      header nav ul {
        height: 100%;
        position: absolute;
        white-space: nowrap; }
        header nav ul > li > a {
          padding: 0 1.07143rem;
          font-size: 1.27273vw;
          line-height: 2.14286rem; } }
      @media (min-width: 901px) and (max-width: 1100px) {
        header nav ul > li > a {
          font-size: 14px; } }
      @media (min-width: 901px) and (min-width: 1501px) {
        header nav ul > li > a {
          font-size: 19.09091px; } }
  @media (min-width: 901px) {
        header nav ul.main {
          left: 13.57143rem; }
        header nav ul.social {
          right: 0; }
          header nav ul.social > li > a {
            padding: 0 0.42857rem; }
            header nav ul.social > li > a .svg-img {
              width: 1.36364vw; } }
          @media (min-width: 901px) and (max-width: 1000px) {
            header nav ul.social > li > a .svg-img {
              width: 13.63636px; } }
          @media (min-width: 901px) and (min-width: 1501px) {
            header nav ul.social > li > a .svg-img {
              width: 20.45455px; } }
  @media (min-width: 901px) {
          header nav ul.social > li.language {
            margin-right: 0.85714rem; } }
  @media (max-width: 900px) {
    header .hamburger-hide {
      display: none; }
    header nav {
      width: 300px;
      height: 100vh;
      padding: 40px;
      padding-top: 70px;
      position: fixed;
      right: -300px;
      top: 0;
      z-index: 1;
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      background-color: black;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      transform: translate(0%, 0);
      transition: transform 0.5s; } }
  @media (max-width: 900px) and (max-width: 500px) {
    header nav {
      width: 250px;
      right: -250px;
      padding: 30px;
      padding-top: 70px; } }
  @media (max-width: 900px) {
      header nav ul.main {
        margin-bottom: 25px; }
        header nav ul.main > li {
          display: block; }
          header nav ul.main > li > a {
            display: block;
            height: 100%;
            padding: 8px 0;
            font-size: 1.81818vw;
            line-height: 1.2; } }
        @media (max-width: 900px) and (max-width: 1000px) {
          header nav ul.main > li > a {
            font-size: 18.18182px; } }
        @media (max-width: 900px) and (min-width: 1501px) {
          header nav ul.main > li > a {
            font-size: 27.27273px; } }
  @media (max-width: 900px) {
      header nav ul.social {
        height: 38px;
        margin-left: -20px;
        margin-right: -15px; }
        header nav ul.social > li {
          width: 25%; }
          header nav ul.social > li > a .svg-img {
            width: 25px;
            margin: 0 auto; }
      header nav .social-hd {
        margin-bottom: 15px;
        font-size: 1.09091vw;
        text-transform: uppercase; } }
    @media (max-width: 900px) and (max-width: 1100px) {
      header nav .social-hd {
        font-size: 12px; } }
    @media (max-width: 900px) and (min-width: 1501px) {
      header nav .social-hd {
        font-size: 16.36364px; } }
  @media (max-width: 900px) {
    header.light nav {
      border-left: 1px solid rgba(0, 0, 0, 0.1);
      background-color: white; }
    header.nav-visible nav {
      transform: translate(-100%, 0); }
    header.nav-visible a.hamburger .show-nav {
      display: none; }
    header.nav-visible a.hamburger .close-nav {
      display: block; } }

main {
  min-height: 100vh;
  background-color: white;
  overflow: hidden; }
  header.fixed:not(.featured) + main {
    padding-top: 8rem; }

footer {
  padding-top: 1.42857rem;
  padding-bottom: 1.78571rem;
  background-color: black;
  color: white;
  text-align: center; }

ul.categories-grid {
  margin-left: -1.07143rem;
  margin-right: -1.07143rem;
  margin-top: -1.07143rem;
  margin-bottom: -1.07143rem;
  display: flex;
  flex-wrap: wrap; }
  ul.categories-grid > * {
    padding-left: 1.07143rem;
    padding-right: 1.07143rem; }
  ul.categories-grid > * {
    padding-top: 1.07143rem;
    padding-bottom: 1.07143rem; }
  ul.categories-grid > li {
    width: 50%; }
    @media (max-width: 900px) {
      ul.categories-grid > li {
        width: 100%; } }
    ul.categories-grid > li > a {
      display: block;
      background-color: black;
      overflow: hidden; }
      ul.categories-grid > li > a::before {
        content: "";
        display: block;
        padding-bottom: 66.66667%; }
      ul.categories-grid > li > a .background {
        transform: scale(1.01);
        transition: transform 0.25s; }
      ul.categories-grid > li > a .category-hover {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.4);
        color: white;
        text-shadow: 0 0 0.71429rem black;
        text-align: center;
        opacity: 1;
        transition: background-color 0.25s, color 0.25s, opacity 0.25s; }
      ul.categories-grid > li > a .category-hover-inner {
        width: 100%;
        padding: 0 1.07143rem;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%); }
      ul.categories-grid > li > a h2 {
        font-size: 2.72727vw;
        line-height: 1.2; }
        @media (max-width: 900px) {
          ul.categories-grid > li > a h2 {
            font-size: 24.54545px; } }
        @media (min-width: 1501px) {
          ul.categories-grid > li > a h2 {
            font-size: 40.90909px; } }
      ul.categories-grid > li > a:hover .background {
        transform: scale(1.1); }
      ul.categories-grid > li > a:hover .category-hover {
        background-color: rgba(0, 0, 0, 0.8);
        color: #dc911b;
        opacity: 1; }

ul.productions-grid {
  margin-left: -0.35714rem;
  margin-right: -0.35714rem;
  margin-top: -0.35714rem;
  margin-bottom: -0.35714rem;
  display: flex;
  flex-wrap: wrap; }
  ul.productions-grid > * {
    padding-left: 0.35714rem;
    padding-right: 0.35714rem; }
  ul.productions-grid > * {
    padding-top: 0.35714rem;
    padding-bottom: 0.35714rem; }
  ul.productions-grid > li {
    display: block;
    width: 33.33333%;
    opacity: 0; }
    @media (max-width: 800px) {
      ul.productions-grid > li {
        width: 50%; } }
    @media (max-width: 440px) {
      ul.productions-grid > li {
        width: 100%; } }
    ul.productions-grid > li > a {
      display: block;
      background-color: black;
      overflow: hidden; }
      ul.productions-grid > li > a::before {
        content: "";
        display: block;
        padding-bottom: 100%; }
      ul.productions-grid > li > a .background {
        transform: scale(1.01);
        transition: transform 0.25s; }
      ul.productions-grid > li > a .production-hover {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
        text-align: center;
        opacity: 0;
        transition: opacity 0.25s; }
      ul.productions-grid > li > a .production-hover-inner {
        width: 100%;
        padding: 0 1.07143rem;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%); }
      ul.productions-grid > li > a h2 {
        font-size: 1.81818vw;
        line-height: 1.2; }
        @media (max-width: 1000px) {
          ul.productions-grid > li > a h2 {
            font-size: 18.18182px; } }
        @media (min-width: 1501px) {
          ul.productions-grid > li > a h2 {
            font-size: 27.27273px; } }
      ul.productions-grid > li > a h3 {
        margin-top: 0.57143rem;
        font-size: 1.45455vw;
        font-weight: 300; }
        @media (max-width: 1100px) {
          ul.productions-grid > li > a h3 {
            font-size: 16px; } }
        @media (min-width: 1501px) {
          ul.productions-grid > li > a h3 {
            font-size: 21.81818px; } }
      ul.productions-grid > li > a:hover .background {
        transform: scale(1.1); }
      ul.productions-grid > li > a:hover .production-hover {
        opacity: 1; }

.section-slideshow {
  margin-bottom: 3.57143rem; }
  @media (max-width: 800px) {
    .section-slideshow {
      margin-bottom: 2.85714rem; } }
  @media (max-width: 500px) {
    .section-slideshow .container {
      padding: 0; } }
  .section-slideshow ul.slideshow-slides {
    min-height: 31.71958rem;
    background-color: black;
    overflow: hidden; }
    .section-slideshow ul.slideshow-slides > li {
      display: none;
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }
      .section-slideshow ul.slideshow-slides > li:first-child, .section-slideshow ul.slideshow-slides > li.active, .section-slideshow ul.slideshow-slides > li.animating {
        display: block; }
  .section-slideshow a.slideshow-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3.28571rem;
    height: 3.28571rem;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: black;
    color: white; }
    .section-slideshow a.slideshow-arrow > * {
      min-width: 0; }
    .section-slideshow a.slideshow-arrow .svg-img {
      width: 1rem;
      margin: 0;
      opacity: 0.6; }
    .section-slideshow a.slideshow-arrow.left {
      left: 0; }
      .section-slideshow a.slideshow-arrow.left .svg-img {
        left: -0.14286rem; }
    .section-slideshow a.slideshow-arrow.right {
      right: 0; }
      .section-slideshow a.slideshow-arrow.right .svg-img {
        right: -0.14286rem; }
    .section-slideshow a.slideshow-arrow:hover .svg-img {
      opacity: 1; }
  .section-slideshow .slideshow-nav {
    height: 0;
    position: absolute;
    bottom: 3.18182vw;
    left: 0;
    right: 0;
    font-size: 0;
    text-align: center; }
    @media (max-width: 900px) {
      .section-slideshow .slideshow-nav {
        bottom: 28.63636px; } }
    @media (min-width: 1501px) {
      .section-slideshow .slideshow-nav {
        bottom: 47.72727px; } }
    .section-slideshow .slideshow-nav ul {
      display: inline-flex;
      justify-content: space-between; }
      .section-slideshow .slideshow-nav ul > * {
        min-width: 0; }
      .section-slideshow .slideshow-nav ul > li {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.18182vw;
        height: 2.18182vw;
        cursor: pointer; }
        .section-slideshow .slideshow-nav ul > li > * {
          min-width: 0; }
        @media (max-width: 900px) {
          .section-slideshow .slideshow-nav ul > li {
            width: 19.63636px;
            height: 19.63636px; } }
        @media (min-width: 1501px) {
          .section-slideshow .slideshow-nav ul > li {
            width: 32.72727px;
            height: 32.72727px; } }
        .section-slideshow .slideshow-nav ul > li::after {
          content: "";
          display: block;
          width: 1vw;
          height: 1vw;
          border: 1px solid rgba(0, 0, 0, 0.1);
          border-radius: 50%;
          background-color: white; }
          @media (max-width: 900px) {
            .section-slideshow .slideshow-nav ul > li::after {
              width: 9px;
              height: 9px; } }
          @media (min-width: 1501px) {
            .section-slideshow .slideshow-nav ul > li::after {
              width: 15px;
              height: 15px; } }
        .section-slideshow .slideshow-nav ul > li.active::after {
          background-color: #cb4f1c; }

.section-production-info .info-content {
  display: flex;
  justify-content: space-between;
  flex-direction: column; }
  .section-production-info .info-content > * {
    min-width: 0; }

.section-production-info .info-logo .logo::before {
  content: "";
  display: block;
  padding-bottom: 72.34195%; }

.section-production-info .info-video .video::before {
  content: "";
  display: block;
  padding-bottom: 56.25%; }

.section-production-info .info-video .video iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0; }

.section-production-info .info-media {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2.14286rem; }
  .section-production-info .info-media > * {
    min-width: 0; }
  .section-production-info .info-media .info-logo {
    width: 47%;
    padding-right: 3.92857rem; }
  .section-production-info .info-media .info-video {
    width: 53%; }

.section-production-info .info-body {
  display: flex;
  justify-content: space-between; }
  .section-production-info .info-body > * {
    min-width: 0; }
  .section-production-info .info-body .info-body-meta {
    width: 47%;
    padding-right: 3.92857rem; }
    .section-production-info .info-body .info-body-meta > * + * {
      margin-top: 2.14286rem; }
    .section-production-info .info-body .info-body-meta ul.meta-links {
      display: flex;
      justify-content: flex-start;
      margin-left: -0.85714rem; }
      .section-production-info .info-body .info-body-meta ul.meta-links > * {
        min-width: 0; }
      .section-production-info .info-body .info-body-meta ul.meta-links > li {
        display: block;
        width: 3.57143rem;
        height: 2.5rem; }
        .section-production-info .info-body .info-body-meta ul.meta-links > li > a {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          color: black; }
          .section-production-info .info-body .info-body-meta ul.meta-links > li > a > * {
            min-width: 0; }
          .section-production-info .info-body .info-body-meta ul.meta-links > li > a:hover {
            color: #dc911b; }
          .section-production-info .info-body .info-body-meta ul.meta-links > li > a .svg-img {
            width: 1.57143rem;
            height: 1.57143rem; }
    .section-production-info .info-body .info-body-meta ul.meta-buttons > li {
      display: block; }
      .section-production-info .info-body .info-body-meta ul.meta-buttons > li + li {
        margin-top: 1.28571rem; }
      .section-production-info .info-body .info-body-meta ul.meta-buttons > li .button {
        display: block;
        width: 100%; }
    .section-production-info .info-body .info-body-meta table.meta-details td {
      width: 50%;
      padding: 0.85714rem 0.57143rem;
      border-bottom: 2px solid white;
      background-color: #ebebeb;
      background-clip: padding-box;
      font-size: 1.27273vw;
      color: #5f5f5f; }
      @media (max-width: 1100px) {
        .section-production-info .info-body .info-body-meta table.meta-details td {
          font-size: 14px; } }
      @media (min-width: 1501px) {
        .section-production-info .info-body .info-body-meta table.meta-details td {
          font-size: 19.09091px; } }
      .section-production-info .info-body .info-body-meta table.meta-details td:first-child {
        padding-right: 2.14286rem; }
    .section-production-info .info-body .info-body-meta .meta-networks > ul {
      margin-left: -0.89286rem;
      margin-right: -0.89286rem;
      margin-top: -0.89286rem;
      margin-bottom: -0.89286rem; }
      .section-production-info .info-body .info-body-meta .meta-networks > ul > * {
        margin-left: 0.89286rem;
        margin-right: 0.89286rem; }
      .section-production-info .info-body .info-body-meta .meta-networks > ul > * {
        margin-top: 0.89286rem;
        margin-bottom: 0.89286rem; }
      .section-production-info .info-body .info-body-meta .meta-networks > ul > li {
        display: inline-block;
        vertical-align: middle; }
        .section-production-info .info-body .info-body-meta .meta-networks > ul > li img {
          width: auto;
          max-width: 10rem;
          max-height: 4.28571rem; }
  .section-production-info .info-body .info-body-description {
    width: 53%; }
    .section-production-info .info-body .info-body-description > * + * {
      margin-top: 2.14286rem; }
    .section-production-info .info-body .info-body-description .description-awards > ul {
      margin-left: -0.35714rem;
      margin-right: -0.35714rem;
      margin-top: -0.35714rem;
      margin-bottom: -0.35714rem; }
      .section-production-info .info-body .info-body-description .description-awards > ul > * {
        margin-left: 0.35714rem;
        margin-right: 0.35714rem; }
      .section-production-info .info-body .info-body-description .description-awards > ul > * {
        margin-top: 0.35714rem;
        margin-bottom: 0.35714rem; }
      .section-production-info .info-body .info-body-description .description-awards > ul > li {
        display: inline-flex;
        align-items: center;
        vertical-align: bottom;
        min-height: 3.71429rem;
        padding: 0.42857rem;
        border: 1px solid #ccc;
        font-size: 1.09091vw; }
        .section-production-info .info-body .info-body-description .description-awards > ul > li > * {
          min-width: 0; }
        @media (max-width: 1100px) {
          .section-production-info .info-body .info-body-description .description-awards > ul > li {
            font-size: 12px; } }
        @media (min-width: 1501px) {
          .section-production-info .info-body .info-body-description .description-awards > ul > li {
            font-size: 16.36364px; } }
        .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo {
          flex-shrink: 0; }
          .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo img {
            max-width: 11.63636vw;
            max-height: 3.63636vw; }
            @media (max-width: 1100px) {
              .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo img {
                max-width: 128px;
                max-height: 40px; } }
            @media (min-width: 1501px) {
              .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo img {
                max-width: 174.54545px;
                max-height: 54.54545px; } }
          .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo.is-large img {
            max-width: 17.45455vw;
            max-height: 5.45455vw; }
            @media (max-width: 1100px) {
              .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo.is-large img {
                max-width: 192px;
                max-height: 60px; } }
            @media (min-width: 1501px) {
              .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo.is-large img {
                max-width: 261.81818px;
                max-height: 81.81818px; } }
        .section-production-info .info-body .info-body-description .description-awards > ul > li > .text {
          padding: 0 0.36364vw; }
          @media (max-width: 1100px) {
            .section-production-info .info-body .info-body-description .description-awards > ul > li > .text {
              padding: 0 4px; } }
          @media (min-width: 1501px) {
            .section-production-info .info-body .info-body-description .description-awards > ul > li > .text {
              padding: 0 5.45455px; } }
        .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo + .text {
          margin-left: 0.54545vw; }
          @media (max-width: 1100px) {
            .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo + .text {
              margin-left: 6px; } }
          @media (min-width: 1501px) {
            .section-production-info .info-body .info-body-description .description-awards > ul > li > .logo + .text {
              margin-left: 8.18182px; } }

@media (max-width: 800px) {
  .section-production-info .info-logo {
    display: none; }
  .section-production-info .info-media {
    order: 1;
    margin-bottom: 0;
    margin-top: 2.14286rem; }
    .section-production-info .info-media .info-video {
      width: 100%; }
  .section-production-info .info-body {
    flex-direction: column; }
    .section-production-info .info-body .info-body-meta, .section-production-info .info-body .info-body-description {
      width: 100%; }
    .section-production-info .info-body .info-body-meta {
      order: 1;
      padding-right: 0; }
      .section-production-info .info-body .info-body-meta > :first-child {
        margin-top: 2.14286rem; } }

.section-production-nav {
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  background-color: #dc911b; }
  .section-production-nav .nav-content {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    .section-production-nav .nav-content > * {
      min-width: 0; }
    .section-production-nav .nav-content > a {
      display: block;
      height: 5rem;
      padding: 0 1.42857rem;
      font-size: 1.45455vw;
      font-weight: 300;
      color: white;
      line-height: 5rem;
      text-transform: uppercase;
      white-space: nowrap;
      opacity: 1; }
      @media (max-width: 1100px) {
        .section-production-nav .nav-content > a {
          font-size: 16px; } }
      @media (min-width: 1501px) {
        .section-production-nav .nav-content > a {
          font-size: 21.81818px; } }
      .section-production-nav .nav-content > a:hover {
        opacity: 0.75; }
      .section-production-nav .nav-content > a .svg-img {
        width: 1.07143rem;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto; }
      .section-production-nav .nav-content > a.previous {
        padding-left: 2.5rem; }
        .section-production-nav .nav-content > a.previous .svg-img {
          left: 0; }
      .section-production-nav .nav-content > a.next {
        padding-right: 2.5rem; }
        .section-production-nav .nav-content > a.next .svg-img {
          right: 0; }

.production-2019-main {
  padding-top: 1.42857rem;
  padding-bottom: 3.57143rem; }

.production-2019-content {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
  margin-top: -1.25rem;
  margin-bottom: -1.25rem;
  display: flex; }
  .production-2019-content > * {
    margin-left: 1.25rem;
    margin-right: 1.25rem; }
  .production-2019-content > * {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem; }
  @media (max-width: 700px) {
    .production-2019-content {
      flex-direction: column; } }

.production-2019-col {
  flex: 1; }

.production-2019-poster {
  display: block; }

.production-2019-links {
  margin-left: -0.71429rem;
  margin-right: -0.71429rem;
  margin-top: -0.71429rem;
  margin-bottom: -0.71429rem;
  margin-top: 0.71429rem;
  display: flex;
  flex-wrap: wrap; }
  .production-2019-links > * {
    margin-left: 0.71429rem;
    margin-right: 0.71429rem; }
  .production-2019-links > * {
    margin-top: 0.71429rem;
    margin-bottom: 0.71429rem; }
  .production-2019-links-item {
    display: block; }

.production-2019-title {
  font-size: 2.72727vw;
  line-height: 1.2; }
  @media (max-width: 900px) {
    .production-2019-title {
      font-size: 24.54545px; } }
  @media (min-width: 1501px) {
    .production-2019-title {
      font-size: 40.90909px; } }

.production-2019-videos {
  margin-top: 1.42857rem; }
  .production-2019-videos-item {
    display: block; }
    .production-2019-videos-item::before {
      content: "";
      display: block;
      padding-bottom: 56.25%; }
    .production-2019-videos-item + * {
      margin-top: 1.42857rem; }
    .production-2019-videos-item iframe {
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0; }

.production-2019-description {
  margin-top: 2.14286rem; }

.production-2019-details {
  margin-left: -1.07143rem;
  margin-right: -1.07143rem;
  margin-top: -1.07143rem;
  margin-bottom: -1.07143rem;
  margin-top: 1.07143rem;
  display: flex;
  flex-wrap: wrap; }
  .production-2019-details > * {
    padding-left: 1.07143rem;
    padding-right: 1.07143rem; }
  .production-2019-details > * {
    padding-top: 1.07143rem;
    padding-bottom: 1.07143rem; }
  .production-2019-details-item {
    display: block;
    min-width: 50%; }
  .production-2019-details dt, .production-2019-details dd {
    margin: 0;
    padding: 0; }
  .production-2019-details dt {
    color: #666; }

.production-2019-networks {
  margin-left: -0.71429rem;
  margin-right: -0.71429rem;
  margin-top: -0.71429rem;
  margin-bottom: -0.71429rem;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .production-2019-networks > * {
    margin-left: 0.71429rem;
    margin-right: 0.71429rem; }
  .production-2019-networks > * {
    margin-top: 0.71429rem;
    margin-bottom: 0.71429rem; }
  .production-2019-networks-item {
    display: block; }
    .production-2019-networks-item img {
      width: auto;
      max-width: 7.27273vw;
      max-height: 3.63636vw; }
      @media (max-width: 1100px) {
        .production-2019-networks-item img {
          max-width: 80px;
          max-height: 40px; } }
      @media (min-width: 1501px) {
        .production-2019-networks-item img {
          max-width: 109.09091px;
          max-height: 54.54545px; } }

.production-2019-awards {
  margin-left: -0.71429rem;
  margin-right: -0.71429rem;
  margin-top: -0.71429rem;
  margin-bottom: -0.71429rem;
  margin-top: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center; }
  .production-2019-awards > * {
    margin-left: 0.71429rem;
    margin-right: 0.71429rem; }
  .production-2019-awards > * {
    margin-top: 0.71429rem;
    margin-bottom: 0.71429rem; }
  .production-2019-awards-item {
    display: flex;
    align-items: center;
    min-height: 3.71429rem;
    min-height: 4.72727vw;
    font-size: 1.09091vw; }
    @media (max-width: 1100px) {
      .production-2019-awards-item {
        min-height: 52px; } }
    @media (min-width: 1501px) {
      .production-2019-awards-item {
        min-height: 70.90909px; } }
    @media (max-width: 1100px) {
      .production-2019-awards-item {
        font-size: 12px; } }
    @media (min-width: 1501px) {
      .production-2019-awards-item {
        font-size: 16.36364px; } }
    .production-2019-awards-item-logo {
      flex-shrink: 0; }
      .production-2019-awards-item-logo img {
        max-width: 11.63636vw;
        max-height: 3.63636vw; }
        @media (max-width: 1100px) {
          .production-2019-awards-item-logo img {
            max-width: 128px;
            max-height: 40px; } }
        @media (min-width: 1501px) {
          .production-2019-awards-item-logo img {
            max-width: 174.54545px;
            max-height: 54.54545px; } }
      .production-2019-awards-item-logo.is-large img {
        max-width: 17.45455vw;
        max-height: 5.45455vw; }
        @media (max-width: 1100px) {
          .production-2019-awards-item-logo.is-large img {
            max-width: 192px;
            max-height: 60px; } }
        @media (min-width: 1501px) {
          .production-2019-awards-item-logo.is-large img {
            max-width: 261.81818px;
            max-height: 81.81818px; } }
    .production-2019-awards-item-text {
      margin-left: 0.90909vw; }
      @media (max-width: 1100px) {
        .production-2019-awards-item-text {
          margin-left: 10px; } }
      @media (min-width: 1501px) {
        .production-2019-awards-item-text {
          margin-left: 13.63636px; } }

.production-2019-slideshow {
  margin-bottom: 0;
  margin-top: 3.57143rem; }

.production-2019-nav {
  position: relative; }

.section-services-list {
  color: white; }
  .section-services-list ul.services-list > li {
    display: flex;
    justify-content: space-between;
    min-height: 25.71429rem;
    transform: translate(-100%, 0); }
    .section-services-list ul.services-list > li > * {
      min-width: 0; }
    .section-services-list ul.services-list > li:nth-child(1) {
      background-color: #1aa8a3; }
    .section-services-list ul.services-list > li:nth-child(2) {
      background-color: #dc911b; }
    .section-services-list ul.services-list > li:nth-child(3) {
      background-color: #cb4f1c; }
    .section-services-list ul.services-list > li:nth-child(4) {
      background-color: #c20e1a; }
  .section-services-list ul.services-list .service-title {
    width: 43%;
    padding: 5.71429rem 0;
    padding-left: 6.81818vw;
    padding-right: 3.92857rem;
    background-color: rgba(255, 255, 255, 0.04);
    text-align: center; }
    @media (min-width: 1501px) {
      .section-services-list ul.services-list .service-title {
        padding-left: 102.27273px; } }
    .section-services-list ul.services-list .service-title img {
      width: 8.57143rem;
      max-height: 8.57143rem;
      margin: 0 auto;
      margin-bottom: 2.14286rem; }
    .section-services-list ul.services-list .service-title h2 {
      line-height: 1.2; }
  .section-services-list ul.services-list .service-description {
    width: 57%;
    padding: 3.57143rem 0;
    padding-left: 2rem;
    padding-right: 6.81818vw;
    background-color: rgba(0, 0, 0, 0.04); }
    @media (min-width: 1501px) {
      .section-services-list ul.services-list .service-description {
        padding-right: 102.27273px; } }
  @media (max-width: 700px) {
    .section-services-list ul.services-list > li {
      display: block; }
    .section-services-list ul.services-list .service-title, .section-services-list ul.services-list .service-description {
      width: auto;
      padding: 2.85714rem 0;
      padding-left: 6.81818vw;
      padding-right: 6.81818vw; } }
  @media (max-width: 700px) and (min-width: 1501px) {
    .section-services-list ul.services-list .service-title, .section-services-list ul.services-list .service-description {
      padding-left: 102.27273px;
      padding-right: 102.27273px; } }

@media (min-width: 701px) {
  .section-team-header .h2 {
    padding-right: 25%; } }

.section-team-list ul.team-grid {
  margin-left: -0.35714rem;
  margin-right: -0.35714rem;
  margin-top: -0.35714rem;
  margin-bottom: -0.35714rem;
  display: flex;
  flex-wrap: wrap; }
  .section-team-list ul.team-grid > * {
    padding-left: 0.35714rem;
    padding-right: 0.35714rem; }
  .section-team-list ul.team-grid > * {
    padding-top: 0.35714rem;
    padding-bottom: 0.35714rem; }
  .section-team-list ul.team-grid > li {
    display: block;
    width: 16.66667%;
    opacity: 0; }
    @media (max-width: 1100px) {
      .section-team-list ul.team-grid > li {
        width: 20%; } }
    @media (max-width: 800px) {
      .section-team-list ul.team-grid > li {
        width: 25%; } }
    @media (max-width: 600px) {
      .section-team-list ul.team-grid > li {
        width: 33.33333%; } }
    @media (max-width: 440px) {
      .section-team-list ul.team-grid > li {
        width: 50%; } }
    .section-team-list ul.team-grid > li .person {
      background-color: black; }
      .section-team-list ul.team-grid > li .person::before {
        content: "";
        display: block;
        padding-bottom: 134.78261%; }
    .section-team-list ul.team-grid > li .person-info {
      width: 100%;
      padding: 0.71429rem;
      position: absolute;
      left: 0;
      bottom: 0;
      color: white;
      text-align: left; }
      .section-team-list ul.team-grid > li .person-info h2, .section-team-list ul.team-grid > li .person-info h3 {
        overflow: hidden;
        text-overflow: ellipsis; }
      .section-team-list ul.team-grid > li .person-info h2 {
        font-size: 1.45455vw;
        line-height: 1.2; }
        @media (max-width: 1100px) {
          .section-team-list ul.team-grid > li .person-info h2 {
            font-size: 16px; } }
        @media (min-width: 1501px) {
          .section-team-list ul.team-grid > li .person-info h2 {
            font-size: 21.81818px; } }
      .section-team-list ul.team-grid > li .person-info h3 {
        margin-top: 0.14286rem;
        font-size: 1.09091vw; }
        @media (max-width: 1100px) {
          .section-team-list ul.team-grid > li .person-info h3 {
            font-size: 12px; } }
        @media (min-width: 1501px) {
          .section-team-list ul.team-grid > li .person-info h3 {
            font-size: 16.36364px; } }
    .section-team-list ul.team-grid > li .person-overlay-default, .section-team-list ul.team-grid > li .person-overlay-hover {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
      transition: opacity 0.25s; }
    .section-team-list ul.team-grid > li .person-overlay-default {
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)); }
    .section-team-list ul.team-grid > li .person-overlay-hover {
      background-color: rgba(0, 0, 0, 0.8);
      opacity: 0; }
    .section-team-list ul.team-grid > li:hover .person-overlay-default {
      opacity: 0; }
    .section-team-list ul.team-grid > li:hover .person-overlay-hover {
      opacity: 1; }

.section-news-list ul.news-list {
  margin-left: -0.35714rem;
  margin-right: -0.35714rem;
  margin-top: -0.35714rem;
  margin-bottom: -0.35714rem;
  display: flex;
  flex-direction: column; }
  .section-news-list ul.news-list > * {
    padding-left: 0.35714rem;
    padding-right: 0.35714rem; }
  .section-news-list ul.news-list > * {
    padding-top: 0.35714rem;
    padding-bottom: 0.35714rem; }
  .section-news-list ul.news-list > li {
    display: flex;
    align-items: flex-start; }
    @media (max-width: 500px) {
      .section-news-list ul.news-list > li {
        flex-direction: column; } }
    .section-news-list ul.news-list > li + li {
      margin-top: 2.85714rem;
      padding-top: 2.85714rem; }
    .section-news-list ul.news-list > li .news-list-item-date {
      margin-right: 2.85714rem;
      margin-bottom: 2.14286rem;
      flex-shrink: 0;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 11.42857rem;
      padding-top: 0.21429rem;
      padding-bottom: 2.28571rem;
      text-align: right;
      line-height: 1;
      text-transform: uppercase; }
      .section-news-list ul.news-list > li .news-list-item-date::before {
        content: "";
        display: block;
        position: absolute;
        left: -999999px;
        right: 0;
        bottom: 0;
        height: 0.57143rem;
        background-color: #dc911b; }
      @media (max-width: 500px) {
        .section-news-list ul.news-list > li .news-list-item-date {
          justify-content: flex-start;
          text-align: left; } }
      .section-news-list ul.news-list > li .news-list-item-date-day {
        font-size: 2.72727vw; }
        @media (max-width: 1100px) {
          .section-news-list ul.news-list > li .news-list-item-date-day {
            font-size: 30px; } }
        @media (min-width: 1501px) {
          .section-news-list ul.news-list > li .news-list-item-date-day {
            font-size: 40.90909px; } }
      .section-news-list ul.news-list > li .news-list-item-date-month-and-year {
        margin-top: 0.35714rem;
        font-size: 1.45455vw; }
        @media (max-width: 1100px) {
          .section-news-list ul.news-list > li .news-list-item-date-month-and-year {
            font-size: 16px; } }
        @media (min-width: 1501px) {
          .section-news-list ul.news-list > li .news-list-item-date-month-and-year {
            font-size: 21.81818px; } }

.section-contact-body .contact-body-map {
  display: flex !important; }
  .section-contact-body .contact-body-map > div {
    width: 100%; }
    .section-contact-body .contact-body-map > div > iframe {
      display: block;
      width: 100%;
      height: 100%;
      padding: 0.57143rem;
      position: absolute;
      border: 2px solid #e5e5e5; }
      @media (max-width: 700px) {
        .section-contact-body .contact-body-map > div > iframe {
          height: 28.57143rem;
          position: relative; } }
