.section-services-list {
    $container-padding: config("container-padding");

    color: white;

    ul.services-list {
        $title-width: 43%;
        $description-width: 100% - $title-width;

        & > li {
            @include flex($justify-content: space-between);
            min-height: responsive(360px);
            transform: translate(-100%, 0);

            $colors: (color("turquoise"), color("orange"), color("orange-dark"), color("red"));

            @for $n from 1 through length($colors) {
                &:nth-child(#{$n}) {
                    background-color: nth($colors, $n);
                }
            }
        }

        .service-title {
            width: $title-width;
            padding: responsive(80px) 0;
            @include responsive-no-min((padding-left: $container-padding));
            padding-right: responsive(55px);
            background-color: rgba(white, 0.04);
            text-align: center;

            img {
                width: responsive(120px);
                max-height: responsive(120px);
                margin: 0 auto;
                margin-bottom: responsive(30px);
            }

            h2 {
                @include line-height("tight");
            }
        }

        .service-description {
            width: $description-width;
            padding: responsive(50px) 0;
            padding-left: responsive(28px);
            @include responsive-no-min((padding-right: $container-padding));
            background-color: rgba(black, 0.04);
        }

        @include below-breakpoint("small-tablet") {
            & > li {
                display: block;
            }

            .service-title, .service-description {
                width: auto;
                padding: responsive(40px) 0;
                @include responsive-no-min((
                    padding-left: $container-padding,
                    padding-right: $container-padding,
                ));
            }
        }
    }
}
