/* TYPOGRAPHY */

.h1 {
    padding-bottom: responsive(48px);

    small {
        display: block;
        @include font-size("h1-small", "h1");
        @include line-height("default");
    }

    &::after {
        $image-width: 2000px;
        $image-height: 36px;
        $width: 10000px;
        $height: $image-height / 2;
        
        content: "";
        display: block;
        width: responsive($width);
        height: responsive($height);
        position: absolute;
        right: calc(100% - #{responsive(160px)});
        bottom: 0;
        background-image: url("../images/color-bar.png");
        background-position: center right;
        background-repeat: no-repeat;
        background-size: (($image-width / 2) / $width * 100%) 100%;
        @include background-color("orange-dark");
    }

    &.align-right {
        @include inline-block($vertical-align: top);

        @include above-breakpoint("small-mobile") {
            min-width: responsive(355px);
        }

        &::after {
            right: 0;
        }
    }

    &.right {
        text-align: right;

        &::after {
            right: auto;
            left: calc(100% - #{responsive(160px)});
            background-position: center left;
        }
    }

    &.black-bar {
        &::after {
            background-image: none;
            background-color: black;
            right: calc(100% - #{responsive(225px)});
        }
    }

    &.no-bar {
        padding-bottom: 0;
        
        &::after {
            display: none;
        }
    }
}


/* BUTTONS AND DROPDOWNS */

.button {
    @extend button;
    @include inline-block($vertical-align: top);
    width: auto;
    padding: responsive(12px) responsive(16px);
    border: responsive(2px) solid black;
    background-color: rgba(white, 0);
    color: black;
    text-align: center;
    @include ellipsis;
    transition: border-color 0.25s, background-color 0.25s, color 0.25s;

    &:hover {
        border-color: color("orange");
        @include color("orange");
    }

    &:active {
        transition: none;
    }

    &.small {
        padding: responsive(8px) responsive(12px);
        @include font-size("small");
    }

    &.filled {
        padding: responsive(14px) responsive(18px);
        border: none;
        color: white;
        @include background-color-lighter-on-hover(black);

        &.small {
            padding: responsive(10px) responsive(14px);
        }

        &.orange {
            @include background-color-lighter-on-hover("orange");
        }

        &.orange-dark {
            @include background-color-lighter-on-hover("orange-dark");
        }

        &.red {
            @include background-color-lighter-on-hover("red");
        }

        &.turquoise {
            @include background-color-lighter-on-hover("turquoise");
        }
    }
}

.dropdown {
    @include inline-block($vertical-align: top);
    max-width: 100%;

    .button {
        display: block;
        padding-left: responsive(40px);
        padding-right: responsive(47px);

        .svg-img {
            width: responsive(25px);
            position: absolute;
            right: responsive(12px);
            top: 0;
            bottom: 0;
        }

        .dropdown-arrow-up {
            display: none;
        }
    }

    .dropdown-menu {
        display: none;
        border: responsive(2px) solid black;
        border-top-width: 0;
        background-color: rgba(white, 0);
        transition: border-color 0.25s, background-color 0.25s;

        & > ul {
            padding: responsive(3px) 0;

            & > li {
                display: block;

                & > a {
                    display: block;
                    height: responsive(40px);
                    margin: 0 -1px;
                    @include font-size("default");
                    color: black;
                    text-align: center;
                    @include ellipsis($no-wrap: true);

                    .dropdown-option-title {
                        width: 100%;
                        position: absolute;
                        @include valign-middle;
                    }

                    &:hover {
                        @include color("orange");
                    }

                    &:active {
                        @include background-color("orange");
                        color: white;
                    }
                }
            }
        }
    }

    &.open {
        .button {
            .dropdown-arrow-down {
                display: none;
            }

            .dropdown-arrow-up {
                display: block;
            }
        }
    }

    &:hover {
        .button, .dropdown-menu {
            border-color: color("orange");
            background-color: rgba(white, 1);
        }
    }
}


/* CONTAINERS */

.bg-dark {
    background-color: black;
    color: white;
}

.section-header {
    img {
        width: 100%;
    }
}


/* BACKGROUNDS */

.background-triangle::before {
    content: "";
    display: block;
    width: 100%;
    position: absolute;
    bottom: -100vw;
    border: 100vw solid transparent;
}

.background-triangle-productions::before {
    right: 0;
    border-right-color: color("turquoise");
}

.background-triangle-team::before {
    left: 0;
    border-left-color: color("orange");
}
